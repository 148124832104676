import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const PaymentConfirmation = async (id, programType) => {
  console.log("post method called on frontend", id);
  const request =
    programType === "event"
      ? "event-payment-confirmed"
      : "course-payment-confirmed";
  try {
    const res = await axios.post(
      `${apiURL}/${request}`,
      { participant_id: id, programType: programType },
      {
        withCredentials: true,
      }
    );
    console.log("response returned", res);
    return res;
  } catch (e) {
    console.error("error thrown", e);
    throw e;
  }
};

export default PaymentConfirmation;
