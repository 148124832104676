import axios from "axios";
import { useEffect, useState } from "react";

const apiURL = process.env.REACT_APP_API_URL;

const GetCalendarPrograms = () => {
  const [currentEvents, setCurrentEvents] = useState([]);
  const [currentCourses, setCurrentCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchAllPrograms = async () => {
    try {
      setLoading(true);
      const eventsResponse = await axios.get(`${apiURL}/events`, {
        withCredentials: true,
      });
      const courseResponse = await axios.get(`${apiURL}/courses`, {
        withCredentials: true,
      });
      const formattedCourses = formatCourses(courseResponse.data);
      setCurrentCourses(formattedCourses);

      const formattedEvents = eventsResponse.data.map((event) => ({
        ...event,
        title: event.name,
        groupId: "events",
        start: formatDate(event.date, event.time, 0),
        end: formatDate(event.date, event.time, 1),
        color: toDistinctHSLColorEvent(event.event_id),
        dateCheck: new Date(event.date).toLocaleDateString(),
        ISO: new Date(event.date).toISOString(),
        year: new Date(event.date).getFullYear(),
        day: new Date(event.date).getDate(),
        month: new Date(event.date).getMonth(),
      }));
      setCurrentEvents(formattedEvents);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  useEffect(() => {
    fetchAllPrograms();
  }, []);

  return { currentEvents, currentCourses, error, loading };
};
export default GetCalendarPrograms;

const formatCourses = (courses) => {
  //   return null;
  const formattedCourses = [];
  for (let i = 0; i < courses.length; i++) {
    const days = JSON.parse(courses[i].days);
    const timeRanges = JSON.parse(courses[i].time);
    console.log(timeRanges[0]);
    for (let j = 0; j < days.length; j++) {
      console.log("day iteration");
      const courseGroupItem = {
        ...courses[i],
        title: courses[i].name,
        startTime: timeRanges[j].split("-")[0].trim(),
        endTime: timeRanges[j].split("-")[1].trim(),
        daysOfWeek: [days[j]],
        color: toDistinctHSLColor(Math.abs(courses[i].course_id)),
        groupId: courses[i].course_id,
        startRecur: courses[i].start,
        endRecur: courses[i].end,
      };
      console.log("item not pushed");
      formattedCourses.push(courseGroupItem);
      console.log("item pushed");
    }
  }
  console.log("formattedCourses", formattedCourses);
  return formattedCourses;
};

const toDistinctHSLColor = (id) => {
  const hue = (id * 25) % 360;
  const saturation = 70;
  const lightness = 50;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`; // Return HSL color
};

const toDistinctHSLColorEvent = (id) => {
  const hue = (id * 50) % 360;
  const saturation = 70;
  const lightness = 50;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`; // Return HSL color
};

const formatDate = (date, time, index) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1; // Months are 0-indexed, so add 1
  const day = dateObj.getDate();
  const newDate = year + "-" + month + "-" + day + "T";
  const newTime = time.split("-")[index].trim();
  return newDate + newTime;
};
