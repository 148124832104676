import { Box } from "@mui/material";
import EventForm from "./eventForm";

const AddEvent = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "70%",
          justifyContent: "center",
          padding: "5%",
          margin: "5%",
          borderRadius: "7%",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add box shadow
        }}
      >
        <EventForm />
      </Box>
    </Box>
  );
};
export default AddEvent;
