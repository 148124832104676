import React from "react";
import {
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const StepTwo = ({ values, errors, touched, handleBlur, handleChange }) => (
  <Box>
    <Typography variant="h4" sx={{ textAlign: "center", mb: "20px" }}>
      School and other information
    </Typography>

    <Grid container spacing={3}>
      <Grid item xs={12}>
        {schoolSelect(values, errors, touched, handleChange, handleBlur)}
      </Grid>
      <Grid item xs={12}>
        {values.school === "other" && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="filled"
              label="Please specify your school"
              name="otherSchool"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.otherSchool}
              error={!!touched.otherSchool && !!errors.otherSchool}
              helperText={touched.otherSchool || errors.otherSchool}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="filled"
          label="Grade"
          name="grade"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.grade}
          error={!!touched.grade && !!errors.grade}
          helperText={touched.grade || errors.grade}
        />
      </Grid>
      <Grid item xs={6}>
        {genderSelect(values, errors, touched, handleChange, handleBlur)}
      </Grid>
      <Grid item xs={12}>
        <Typography mb={1}>
          Tell us more about your child's passions and capabilities
        </Typography>
        <TextField
          fullWidth
          variant="filled"
          label="Passion and capabilities..."
          name="passion"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.passion}
          error={!!touched.passion && !!errors.passion}
          multiline
          helperText={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {touched.passion && errors.passion}
              <span>{values.passion.length} / 200 characters</span>
            </Box>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography mb={1}>
          Does the student have any preferences for their learning style that we
          should consider in delivering our programming?
        </Typography>
        <TextField
          fullWidth
          variant="filled"
          label="Preferences and learning style..."
          name="preferences"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.preferences}
          error={!!touched.preferences && !!errors.preferences}
          multiline
          helperText={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {touched.preferences && errors.preferences}
              <span>{values.preferences.length} / 200 characters</span>
            </Box>
          }
        />
      </Grid>
    </Grid>
  </Box>
);

const genderSelect = (values, errors, touched, handleChange, handleBlur) => (
  <FormControl
    fullWidth
    variant="filled"
    error={touched.gender && !!errors.gender}
  >
    <InputLabel>Gender</InputLabel>
    <Select
      name="gender"
      value={values.gender}
      onChange={handleChange}
      onBlur={handleBlur}
    >
      <MenuItem value="male">Male</MenuItem>
      <MenuItem value="female">Female</MenuItem>
    </Select>
    {(touched.gender || errors.gender) && (
      <Typography color="error">{errors.gender}</Typography>
    )}
  </FormControl>
);

const schoolSelect = (values, errors, touched, handleChange, handleBlur) => (
  <FormControl
    fullWidth
    variant="filled"
    error={touched.school && !!errors.school}
  >
    <InputLabel>School</InputLabel>
    <Select
      name="school"
      value={values.school}
      onChange={handleChange}
      onBlur={handleBlur}
    >
      <MenuItem value="IST">IST</MenuItem>
      <MenuItem value="Aga Khan Schools">Aga Khan Schools</MenuItem>
      <MenuItem value="Academic International Primary & Secondary School">
        Academic International Primary & Secondary School
      </MenuItem>
      <MenuItem value="Al-Muntazir">Al-Muntazir</MenuItem>
      <MenuItem value="DIA">DIA</MenuItem>
      <MenuItem value="AMSB">AMSB</MenuItem>
      <MenuItem value="FEZA">FEZA</MenuItem>
      <MenuItem value="Stepping Stones">Stepping Stones</MenuItem>
      <MenuItem value="other">Other</MenuItem>
    </Select>
    {(touched.school || errors.school) && (
      <Typography color="error">{errors.school}</Typography>
    )}
  </FormControl>
);

export default StepTwo;
