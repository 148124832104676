import { useState, useEffect } from "react";
import axios from "axios";
import { capitalize } from "@mui/material";

const apiURL = process.env.REACT_APP_API_URL;

const GetAllStudents = () => {
  const [students, setStudents] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true); // Loading state

  const fetchAllStudents = async () => {
    try {
      const res = await axios.get(`${apiURL}/students`, {
        withCredentials: true,
      });
      const formattedData = res.data.map((student) => ({
        ...student,
        student_name:
          capitalize(student.first_name) + " " + capitalize(student.last_name),
        parent_name:
          capitalize(student.parent_first_name) +
          " " +
          capitalize(student.parent_last_name),
        date_of_birth: new Date(student.date_of_birth).toLocaleDateString(
          "en-GB",
          { day: "numeric", month: "short", year: "numeric" }
        ),
      }));
      setStudents(formattedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllStudents();
  }, []);

  console.log(students);

  return { students, loading, fetchAllStudents }; // Return data, loading, and error states
};

export default GetAllStudents;
