import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import LoginModal from "../home/loginModal";
import SignupModal from "../home/signupModal";
import { useModal } from "../../customHooks/useModal";
import { useAuth } from "../../Authentication/AuthContext";
import AttendModal from "./attendeesModal";
import AttendChild from "../../requests/postAttendChild";
import TileModal from "./TileModal";

const EventTiles = ({ filteredEvents }) => {
  const [AttendModalOpen, setAttendModalOpen] = useState(false);
  const [eventID, setEventID] = useState("");
  const [tileModalOpen, setTileModalOpen] = useState(false);
  const [event, setEvent] = useState("");

  const {
    attend,
    attendConfirmed,
    attendError,
    setAttendError,
    postAttendChild,
    setAttendConfirmed,
  } = AttendChild();

  const {
    loginModalOpen,
    signupModalOpen,
    handleLoginCloseModal,
    handleSignupCloseModal,
    handleOpenSignupModal,
    handleLoginOpenModal,
  } = useModal();

  const { isAuthenticated } = useAuth();

  const handleClosedAttendModal = () => {
    setAttendConfirmed(false);
    setEventID("");
    setAttendModalOpen(false);
    setAttendError("");
  };

  const handleBookNow = (eventID) => {
    if (!isAuthenticated) {
      handleLoginOpenModal();
    } else {
      console.log(eventID);
      setEventID(eventID);
      setAttendModalOpen(true);
    }
  };

  const handleTileModalOpen = (event) => {
    setEvent(event);
    setTileModalOpen(true);
  };
  const handleTileModalClose = () => {
    setTileModalOpen(false);
    setAttendError(false);
    setAttendConfirmed(false);
  };

  return (
    <>
      {
        filteredEvents?.length
          ? filteredEvents.map((event) => (
              <Box
                key={event.event_id}
                sx={{
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <Box position="relative">
                  <img
                    src={
                      event.area.toLowerCase().includes("robotics")
                        ? `../../../assets/r.jpg`
                        : event.area.toLowerCase().includes("scientific")
                        ? `../../../assets/s.jpg`
                        : event.area.toLowerCase().includes("entre")
                        ? `../../../assets/e.jpg`
                        : event.area.toLowerCase().includes("creative")
                        ? `../../../assets/c.jpg`
                        : ""
                    }
                    alt={event.area}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <Typography
                    padding="0"
                    margin="0"
                    position="absolute"
                    top="5%"
                    right="5%"
                    backgroundColor="white"
                    p="5px"
                  >
                    TZS {event.price?.toLocaleString()}.00
                  </Typography>
                  <Typography
                    padding="0"
                    margin="0"
                    position="absolute"
                    bottom="2%"
                    right="5%"
                    backgroundColor="white"
                    p="5px"
                    sx={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                  >
                    #event
                  </Typography>
                </Box>
                <Box p={1}>
                  <Typography variant="h5" noWrap color="#ef5340">
                    {event.name}
                  </Typography>
                  <Typography variant="body2" noWrap fontWeight="bold">
                    {event.date?.toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}{" "}
                    (
                    {event.date?.toLocaleDateString("en-GB", {
                      weekday: "short",
                    })}
                    )
                  </Typography>
                  <Typography variant="body2" noWrap>
                    {event.time} EAT
                  </Typography>
                  <Typography variant="body2" noWrap>
                    {event.group.replace("-", " - ")}
                    <span
                      style={{
                        float: "right",
                        color: "#ef5340",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleTileModalOpen(event);
                      }}
                    >
                      View Details
                    </span>
                  </Typography>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px solid #d3d3d3",
                    }}
                  />
                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <img
                        src="https://upstudio.africa/wp-content/uploads/2022/11/favicon.png"
                        alt="img"
                        style={{
                          border: "2px solid #d3d3d3",
                          width: "40px",
                          borderRadius: "50%",
                          padding: "2px",
                        }}
                      />
                    </Box>
                    <Box>
                      <Button
                        sx={{
                          backgroundColor: "#FFD33D",
                          borderRadius: "30px",
                          color: "black",
                          fontWeight: "normal",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#FFC107",
                          },
                          margin: "2px",
                          textAlign: "right",
                          display: "block",
                          mx: "auto",
                          p: "0.5rem",
                        }}
                        onClick={() => {
                          handleBookNow(event.event_id);
                        }}
                      >
                        Book Now
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))
          : ""
        // (
        //   <Typography
        //     variant="body1"
        //     sx={{ gridColumn: "1 / -1", textAlign: "center" }}
        //   >
        //     No events available
        //   </Typography>
        // )
      }
      <LoginModal
        modalOpen={loginModalOpen}
        handleCloseModal={handleLoginCloseModal}
        handleOpenSignupModal={handleOpenSignupModal}
      />
      <SignupModal
        modalOpen={signupModalOpen}
        handleCloseModal={handleSignupCloseModal}
      />
      <AttendModal
        AttendModalOpen={AttendModalOpen}
        handleClosedAttendModal={handleClosedAttendModal}
        eventID={eventID}
        postAttendChild={postAttendChild}
        attend={attend}
        attendConfirmed={attendConfirmed}
        attendError={attendError}
      />
      <TileModal
        tileModalOpen={tileModalOpen}
        handleTileModalClose={handleTileModalClose}
        program={event}
        postMethod={postAttendChild}
        error={attendError}
        fetching={attend}
        confirmed={attendConfirmed}
      />
    </>
  );
};

export default EventTiles;
