import { capitalize } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";

const apiURL = process.env.REACT_APP_API_URL;

const GetParent = () => {
  const [parent, setParent] = useState({});
  const [error, setError] = useState("");

  const fetchParent = async () => {
    try {
      const res = await axios.get(`${apiURL}/parent`, {
        withCredentials: true,
      });
      const formattedData = res.data.map((p) => ({
        ...p,
        name: capitalize(p.first_name) + " " + capitalize(p.last_name),
      }));
      setParent(formattedData); // Access the data property of the response
      console.log(formattedData);
    } catch (e) {
      setError(e.message); // Set only the error message for readability
      return e;
    }
  };

  useEffect(() => {
    fetchParent();
  }, []);

  return { parent, error, fetchParent };
};

export default GetParent;
