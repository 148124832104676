import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import PaymentConfirmation from "../../requests/postPaymentConfirmation";
import { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PaidModal = ({
  modalOpen,
  handleCloseModal,
  participantID,
  error,
  setError,
  emailSent,
  setEmailSent,
  paymentConfirmationLoading,
  setPaymentConfirmationLoading,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  console.log(participantID);

  const handleConfirmPayment = async () => {
    setPaymentConfirmationLoading(true);
    setError("");
    try {
      await PaymentConfirmation(participantID, "event");
      setEmailSent(true);
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setPaymentConfirmationLoading(false);
    }
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      sx={{ width: "1000px", minWidth: "100%" }}
    >
      <DialogTitle variant="h3" textAlign="center">
        {emailSent ? "Payment Confirmed" : "Confirm Payment"}
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        {!emailSent && (
          <Typography variant="h6">
            Has this enrollment been paid for?
          </Typography>
        )}
        {emailSent && (
          <>
            <CheckCircleIcon
              style={{
                color: "green",
                fontSize: "4rem",
                textAlign: "center",
              }}
            />
            <Typography variant="h6">
              Confirmation Email has been sent to the parent.
            </Typography>
          </>
        )}
      </DialogContent>
      {!emailSent && (
        <DialogActions
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            paddingBottom: "1rem",
          }}
        >
          <Button
            onClick={handleConfirmPayment}
            color="primary"
            style={{ backgroundColor: colors.greenAccent[600] }}
          >
            {paymentConfirmationLoading ? "Loading..." : "Yes"}
          </Button>
          <Button
            onClick={handleCloseModal}
            color="primary"
            style={{ backgroundColor: "red" }}
          >
            No
          </Button>
        </DialogActions>
      )}
      {error && (
        <Typography
          variant="h6"
          color="error"
          style={{ textAlign: "center", padding: "1rem" }}
        >
          {error.message}
        </Typography>
      )}
    </Dialog>
  );
};

export default PaidModal;
