// StepOne.js
import React from "react";
import { TextField, Grid, Typography, Box } from "@mui/material";

const StepOne = ({ values, errors, touched, handleBlur, handleChange }) => (
  <Box>
    <Typography variant="h4" sx={{ textAlign: "center", mb: "20px" }}>
      Child Details
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="filled"
          label="First Name"
          name="firstname"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.firstname}
          error={touched.firstname && Boolean(errors.firstname)}
          helperText={touched.firstname && errors.firstname}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="filled"
          label="Last Name"
          name="lastname"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.lastname}
          error={touched.lastname && Boolean(errors.lastname)}
          helperText={touched.lastname && errors.lastname}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="filled"
          label="Email"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="filled"
          label="Birth Date"
          type="date"
          name="date_of_birth"
          InputLabelProps={{ shrink: true }} // Ensures label doesn't overlap
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.date_of_birth}
          error={touched.date_of_birth && Boolean(errors.date_of_birth)}
          helperText={touched.date_of_birth && errors.date_of_birth}
        />
      </Grid>
    </Grid>
  </Box>
);

export default StepOne;
