import { Box, Typography, capitalize } from "@mui/material";
import React from "react";
import { useAuth } from "../../Authentication/AuthContext";

const calculateAge = (birthDate) => {
  if (!(birthDate instanceof Date)) return "Calculating...";
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return String(age + " Years");
};

const ChildHeader = ({ selected }) => {
  const { user } = useAuth();
  const age = calculateAge(selected.dob);

  return (
    <Box display="grid" gridTemplateColumns="auto auto" m="30px">
      <Box gridColumn="1">
        {selected.first_name ? (
          <>
            <Typography variant="h2" color="#EF5340;">
              {capitalize(selected.first_name)} {capitalize(selected.last_name)}
            </Typography>
            <Typography variant="p">Age: {age}</Typography>
            {user?.userType === "admin" && (
              <Box display="flex" flexDirection="row" gap={5}>
                <Box>
                  <Typography variant="p">
                    <b>Parent Name:</b>
                    {capitalize(selected.parentFirstName)}{" "}
                    {capitalize(selected.parentLastName)}
                  </Typography>{" "}
                  <br />
                  <Typography variant="p">
                    <b>Parent Email:</b> {selected.parentEmail}
                  </Typography>{" "}
                  <br />
                  <Typography variant="p">
                    <b> Parent Phone No.: </b>
                    {selected.parentNumber}
                  </Typography>{" "}
                  <br />
                </Box>
                <Box>
                  <Typography variant="p">
                    <b>School:</b>
                    {capitalize(selected.school)}{" "}
                  </Typography>{" "}
                  <br />
                  <Typography variant="p">
                    <b>Allergies:</b>{" "}
                    {selected.allergies.length > 0 ? "Yes" : "None"}
                  </Typography>{" "}
                  <br />
                  <Typography variant="p">
                    <b> Pictures: </b>
                    {capitalize(selected.pictures)}
                  </Typography>{" "}
                  <br />
                </Box>
              </Box>
            )}
          </>
        ) : (
          <>
            <Typography variant="h3" color="#EF5340;">
              Add a child :))
            </Typography>
            <Typography variant="p" color="#000;">
              Click the plus icon on the top right to add a child
            </Typography>
          </>
        )}
      </Box>
      {/* Upcoming Programs */}
    </Box>
  );
};

export default ChildHeader;
