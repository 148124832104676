// EditParentDialog.js
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

const ParentModal = ({
  open,
  onClose,
  parent,
  onSave,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}) => {
  const [fname, setFname] = useState(parent[0].first_name);
  const [lname, setLname] = useState(parent[0].last_name);
  const [phoneNumber, setPhoneNumber] = useState(parent[0].phone_number);
  const [email, setEmail] = useState(parent[0].email);
  const [password, setPassword] = useState("");

  const handleSave = () => {
    if (!fname || !lname || !phoneNumber || !email || !password) {
      setSnackbarSeverity("error");
      setSnackbarMessage("All fields are required.");
      setSnackbarOpen(true);
      return;
    }
    if (
      fname === parent[0].first_name &&
      phoneNumber === parent[0].phone_number &&
      email === parent[0].email
    ) {
      setSnackbarSeverity("error");
      setSnackbarMessage("You must change at least one value!");
      setSnackbarOpen(true);
      return;
    }
    onSave({ fname, lname, phone_number: phoneNumber, email, password });
    // onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Parent Details</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="First Name"
          type="text"
          fullWidth
          value={fname}
          onChange={(e) => setFname(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Last Name"
          type="text"
          fullWidth
          value={lname}
          onChange={(e) => setLname(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Phone Number"
          type="text"
          fullWidth
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Enter your password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParentModal;
