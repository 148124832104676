import axios from "axios";
import { useState } from "react";

const apiURL = process.env.REACT_APP_API_URL;

const AttendChild = () => {
  const [attend, setAttend] = useState(false);
  const [attendConfirmed, setAttendConfirmed] = useState(false);
  const [attendError, setAttendError] = useState("");

  const postAttendChild = async (eventID, studentID) => {
    try {
      console.log("post method called on frontend", eventID, studentID);
      setAttend(true);
      const res = await axios.post(
        `${apiURL}/attend-child`,
        { event_id: eventID, student_id: studentID },
        { withCredentials: true }
      );
      console.log(res);
      setAttend(false);
      setAttendConfirmed(true);
    } catch (e) {
      console.log(typeof e.response?.data?.errno);
      setAttend(false);
      setAttendConfirmed(false);
      if (e.response) {
        if (e.response.data?.errno === 1062) {
          setAttendError("Child is already booked to this program!");
        } else {
          setAttendError("Booking failed. Please try again later");
        }
      } else {
        setAttendError("Network error. Please check your connection.");
      }
    }
  };

  console.log(attendConfirmed);

  return {
    attend,
    attendConfirmed,
    attendError,
    setAttendError,
    postAttendChild,
    setAttendConfirmed,
  };
};

export default AttendChild;
