import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { useEffect, useState, useMemo } from "react";

const CalendarSideBar = ({
  currentCourses,
  currentEvents,
  setFilteredPrograms,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const uniqueCourses = useMemo(() => {
    const currentCoursesSet = new Set();
    return currentCourses.filter((course) => {
      if (!currentCoursesSet.has(course.course_id)) {
        currentCoursesSet.add(course.course_id);
        return true;
      }
      return false;
    });
  }, [currentCourses]);

  useEffect(() => {
    setFilteredCourses(currentCourses);
    setFilteredEvents(currentEvents);
  }, [currentCourses, currentEvents]);

  useEffect(() => {
    setFilteredPrograms([...filteredEvents, ...filteredCourses]);
  }, [filteredCourses, filteredEvents]);

  const handleCourseClick = (id) => {
    if (filteredCourses.find((course) => course.course_id === id)) {
      setFilteredCourses(
        filteredCourses.filter((course) => course.course_id !== id)
      );
    } else {
      const found = currentCourses.filter((course) => course.course_id === id);
      if (found) setFilteredCourses((prev) => [...prev, ...found]);
    }
  };

  const handleEventClick = (id) => {
    if (filteredEvents.find((event) => event.event_id === id)) {
      setFilteredEvents(
        filteredEvents.filter((event) => event.event_id !== id)
      );
    } else {
      const found = currentEvents.filter((event) => event.event_id === id);
      if (found) setFilteredEvents((prev) => [...prev, ...found]);
    }
  };

  return (
    <Box
      flex="1 1 30%"
      backgroundColor={colors.primary[400]}
      p="15px"
      borderRadius="4px"
      height="80vh"
      sx={{ overflowY: "scroll", scrollbarWidth: "thin" }}
    >
      <Typography variant="h5">Courses and Events</Typography>
      <List>
        {currentEvents.map((event) => (
          <ListItem
            key={event.event_id}
            sx={{
              backgroundColor: filteredEvents.some(
                (e) => e.event_id === event.event_id
              )
                ? event.color
                : "gray",
              margin: "10px 0",
              borderRadius: "2px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleEventClick(event.event_id);
            }}
          >
            <ListItemText
              primary={event.name}
              secondary={
                <Typography>
                  {new Date(event.date).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </Typography>
              }
            />
          </ListItem>
        ))}
        {uniqueCourses.map((course) => (
          <ListItem
            key={course.course_id}
            sx={{
              backgroundColor: filteredCourses.some(
                (c) => c.course_id === course.course_id
              )
                ? course.color
                : "gray",
              margin: "10px 0",
              borderRadius: "2px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleCourseClick(course.course_id);
            }}
          >
            <ListItemText
              primary={course.name}
              secondary={
                <Typography>
                  {JSON.parse(course.days).map((day) => `${days[day]} `)}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CalendarSideBar;

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
