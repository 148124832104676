import React from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { Box, Typography } from "@mui/material";

const data = [
  { name: "Courses Unpaid", value: 300 },
  { name: "Courses Paid", value: 200 },
];

const COLORS = ["#EF5340", "#4caf50", "#ffa726", "#2196f3"];

const CoursesPieChart = ({ height }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        borderRadius: 2,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Courses Unpaid vs Paid
      </Typography>

      <ResponsiveContainer width="90%" height={height}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            innerRadius={40}
            fill="#8884d8"
            dataKey="value"
            // label
            key="none"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend layout="vertical" align="right" verticalAlign="top" />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CoursesPieChart;
