import { useState, useEffect } from "react";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const GetEventsAttended = ({ selected }) => {
  const [data, setData] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]); // Initialize with an empty array
  const [previousEvents, setPreviousEvents] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const upcomingItems = [];
  const previousItems = [];

  useEffect(() => {
    if (!selected || !selected.id) return;
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${apiURL}/attended-events`,
          { id: selected.id },
          {
            withCredentials: true,
          }
        );

        const formattedData = response.data.forEach((dataObj) => {
          const formattedDate = new Date(dataObj.date);
          const formattedObj = {
            ...dataObj,
            id: dataObj.course_id,
            date: formattedDate.toLocaleDateString("en-GB", {
              // weekday: "long",
              day: "numeric",
              month: "short",
              year: "numeric",
            }),
          };

          // Compare the date directly as a Date object
          if (formattedDate < new Date().setHours(0, 0, 0, 0)) {
            previousItems.push(formattedObj);
          } else {
            upcomingItems.push(formattedObj);
          }
        });

        // setting values
        setUpcomingEvents(upcomingItems);
        setPreviousEvents(previousItems);
        setData(formattedData);
      } catch (e) {
        console.error(e);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selected]);

  return { data, upcomingEvents, previousEvents, loading, error };
};

export default GetEventsAttended;
