import { Box } from "@mui/material";
import * as React from "react";
import { useAuth } from "../../Authentication/AuthContext";
import EventsGrid from "./eventsGrid";
import Tiles from "../tiles/tiles";
import Header from "../../components/Header";

function Events() {
  const { user } = useAuth();
  return (
    <Box m="20px">
      <Header title="Events" subtitle="List of Events" />
      {user?.userType === "admin" ? (
        <EventsGrid />
      ) : (
        <Tiles selectedDays={""} selectedGroup={""} programType={"event"} />
      )}
    </Box>
  );
}

export default Events;
