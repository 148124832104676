import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Fade,
  DialogActions,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState, useEffect } from "react";
import GetChildren from "../../requests/getChildren";
import ChildrenBar from "../children/childBar";
import { useAuth } from "../../Authentication/AuthContext";

const TileModal = ({
  tileModalOpen,
  handleTileModalClose,
  postMethod,
  fetching,
  confirmed,
  error,
  program,
}) => {
  const { data } = GetChildren();
  const [childArray, setChildArray] = useState([]);
  const [selected, setSelected] = useState({});
  const { isAuthenticated } = useAuth();

  console.log(program);
  useEffect(() => {
    if (data && data.length) {
      const uniqueChildren = [];
      const seenNames = new Set();

      data.forEach((obj) => {
        const fullName = `${obj.first_name}-${obj.last_name}`;
        if (!seenNames.has(fullName)) {
          seenNames.add(fullName);
          uniqueChildren.push({
            id: obj.student_id,
            first_name: obj.first_name,
            last_name: obj.last_name,
            dob: new Date(obj.date_of_birth),
          });
        }
      });
      setChildArray(uniqueChildren);
    }
  }, [data]);

  const handleButtonClick = async () => {
    try {
      await postMethod(program.event_id || program.course_id, selected.id);
    } catch (err) {
      console.error("Booking failed:", err);
    }
  };

  useEffect(() => {
    if (program.course_id) {
      const days = program.days.split(",").map((day) => day.trim());
      console.log(days);
      console.log("days");
    }
  }, [program]);

  return (
    <Dialog open={tileModalOpen} onClose={handleTileModalClose}>
      <DialogTitle textAlign="center">
        <Typography
          variant="h4"
          sx={{ textAlign: "center", m: "20px", color: "#ef5340" }}
        >
          {program.name}
        </Typography>
      </DialogTitle>
      {confirmed ? (
        <Fade in={confirmed} timeout={600}>
          <DialogContent sx={{ padding: "4rem" }}>
            <Typography variant="h3" sx={{ textAlign: "center", mb: "20px" }}>
              Child registered!
            </Typography>
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="center">
                <CheckCircleIcon
                  style={{
                    color: "green",
                    fontSize: "10em",
                    textAlign: "center",
                  }}
                />
              </Box>
              <Box display="flex">
                <Typography
                  fontSize="1rem"
                  m="40px"
                  sx={{ textAlign: "center" }}
                >
                  Please check your inbox for a booking confirmation email.
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Fade>
      ) : (
        <Fade in={!confirmed} timeout={600}>
          <DialogContent sx={{ padding: "4rem" }}>
            <Typography variant="body" sx={{ textAlign: "left", mb: "10px" }}>
              <b>{program.event_id ? "Event" : "Course"} Overview: </b>
              {program.description}
            </Typography>

            <br />
            <br />

            <Typography variant="body" sx={{ textAlign: "left" }}>
              <b>{program.event_id ? "Event" : "Course"} Details: </b>
              <br />
              Instructor: {program.instructor}
              <br />
              Area: {program.area}
              <br />
              {program.date && (
                <>
                  Date:{" "}
                  {new Date(program.date).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    weekday: "short",
                  })}
                  <br />
                </>
              )}
              {program.start && (
                <>
                  Course Start:{" "}
                  {new Date(program.start).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    weekday: "short",
                  })}
                  <br />
                </>
              )}
              {program.end && (
                <>
                  Course End:{" "}
                  {new Date(program.end).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    weekday: "short",
                  })}
                  <br />
                </>
              )}
              {program.event_id && (
                <>
                  Time: {to12Hour(program.time)}
                  <br />
                </>
              )}
              {program.course_id && program.days && program.time && (
                <>
                  Days Offered:
                  <ul style={{ marginTop: "0" }}>
                    {program.days.split(",").map((day, index) => (
                      <li key={index}>
                        {day.trim()}:{" "}
                        {to12Hour(JSON.parse(program.time)[index])}
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {program.group?.replace("-", " - ")}
            </Typography>

            {/* Error handling display */}
            <Typography
              sx={{ textAlign: "center", m: "20px 0px", color: "red" }}
            >
              {error}
            </Typography>

            {/* Bottom action bar */}
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              alignItems="center"
              mt="20px"
            >
              {isAuthenticated && (
                <ChildrenBar
                  childArray={childArray}
                  setSelected={setSelected}
                  ml="10px"
                />
              )}

              {/* Booking button */}
              <DialogActions style={{ justifyContent: "center" }}>
                {isAuthenticated && (
                  <Button
                    onClick={handleButtonClick}
                    sx={{
                      backgroundColor: "#FFD33D",
                      borderRadius: "30px",
                      color: "black",
                      fontWeight: "normal",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#FFC107",
                      },
                      margin: "2px",
                      textAlign: "right",
                      display: "block",
                      mx: "auto",
                      p: "0.6rem",
                    }}
                  >
                    {fetching ? "Booking..." : "Book Now"}
                  </Button>
                )}
              </DialogActions>
            </Box>
          </DialogContent>
        </Fade>
      )}
    </Dialog>
  );
};

export default TileModal;

const to12Hour = (timeRange) => {
  let startPeriod = "am";
  let endPeriod = "am";
  const [start, end] = timeRange.split("-").map((time) => time.trim());
  let [startHour, startMinute] = start.split(":").map(Number);
  let [endHour, endMinute] = end.split(":").map(Number);
  if (startHour >= 12) {
    startPeriod = "pm";
    startHour = startHour === 12 ? 12 : startHour - 12;
  } else {
    startPeriod = "am";
    startHour = startHour === 0 ? 12 : startHour;
  }
  if (endHour >= 12) {
    endPeriod = "pm";
    endHour = endHour === 12 ? 12 : endHour - 12;
  } else {
    endPeriod = "am";
    endHour = endHour === 0 ? 12 : endHour;
  }
  return `${startHour}:${startMinute}${startPeriod} - ${endHour}:${endMinute}${endPeriod}`;
};

const daysText = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
