import React, { useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Fade,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";

const apiURL = process.env.REACT_APP_API_URL;

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  date_of_birth: "",
  school: "",
  otherSchool: "",
  grade: "",
  gender: "",
  passion: "",
  disabilities: "",
  preferences: "",
  allergies: "",
  pictures: "",
  termsAccepted: false,
};

// Validation schemas for each step
const validationSchemas = [
  yup.object().shape({
    firstname: yup.string().required("Required field"),
    lastname: yup.string().required("Required field"),
    date_of_birth: yup.date().required("Required field"),
    email: yup
      .string()
      .trim()
      .required("Required field")
      .email("Invalid email"),
  }),
  yup.object().shape({
    school: yup.string().required("Required field"),
    otherSchool: yup
      .string()
      .ensure()
      .when("school", {
        is: "other",
        then: yup.string().required("required"),
      }),
    grade: yup.string().required("Required field"),
    gender: yup.string().required("Required field"),
    passion: yup
      .string()
      .max(200, "Must be 200 characters or less")
      .required("Required field"),
    preferences: yup
      .string()
      .max(200, "Must be 200 characters or less")
      .required("Required field"),
  }),
  yup.object().shape({
    disabilities: yup
      .string()
      .max(200, "Must be 200 characters or less")
      .required("Required field"),
    allergies: yup
      .string()
      .max(400, "Must be 400 characters or less")
      .required("Required field"),
  }),
  yup.object().shape({
    termsAccepted: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("Required"),
    pictures: yup
      .string()
      .oneOf(["yes", "no"], "You must select an option")
      .required("Please pick either yes or no"),
  }),
];

const stepFields = [
  ["firstname", "lastname", "date_of_birth", "email"],
  ["school", "otherSchool", "grade", "gender", "passion", "preferences"],
  ["disabilities", "allergies"],
  ["termsAccepted"],
];

const AddChildForm = ({ modalOpen, handleCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [childAdded, setChildAdded] = useState(false);
  const [step, setStep] = useState(0);

  const handleFormSubmit = async (values, { setSubmitting }) => {
    console.log(values);
    setLoading(true);
    setError("");
    try {
      const res = await axios.post(`${apiURL}/add-child`, values, {
        withCredentials: true,
      });
      setChildAdded(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (e) {
      setError(
        e.response?.data?.Error.includes("ER_DUP_ENTRY")
          ? "Student already exists!"
          : "Child addition failed. Please try again later!"
      );
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const handleNext = async (validateForm, setTouched, values) => {
    console.log(values);
    const fieldsToTouch = stepFields[step].reduce((acc, field) => {
      acc[field] = true;
      return acc;
    }, {});

    setTouched(fieldsToTouch); // Set touched fields
    const errors = await validateForm(); // Validate the form
    if (Object.keys(errors).length === 0) {
      setStep((prevStep) => prevStep + 1); // Move to the next step
    }
  };

  const handleBack = () => setStep((prevStep) => prevStep - 1);

  return (
    <Dialog open={modalOpen} onClose={() => {}} /* Disable default close */>
      <DialogTitle textAlign="center">
        <img
          src="https://upstudio.africa/wp-content/uploads/2022/11/Logoinpill3-300x126.png"
          alt="logo"
          style={{
            width: "175px",
            height: "auto",
            padding: "20px",
            alignSelf: "center",
          }}
        />
      </DialogTitle>
      {childAdded ? (
        <Fade in={childAdded} timeout={600}>
          <DialogContent sx={{ padding: "4rem" }}>
            <Typography variant="h4" sx={{ textAlign: "center", mb: "20px" }}>
              Child has been added!
            </Typography>
            <Box display="flex" justifyContent="center">
              <CheckCircleIcon style={{ color: "green", fontSize: "10em" }} />
            </Box>
          </DialogContent>
        </Fade>
      ) : (
        <Fade in={!childAdded} timeout={600}>
          <DialogContent sx={{ padding: "4rem" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[step]}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                validateForm,
                setFieldValue,
                setTouched,
              }) => (
                <Form>
                  <Box>
                    {/* Render each step based on the current step */}
                    {step === 0 && (
                      <StepOne
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                    {step === 1 && (
                      <StepTwo
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                    {step === 2 && (
                      <StepThree
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                    {step === 3 && (
                      <StepFour
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt="20px">
                    <Button onClick={handleCloseModal} color="error">
                      Cancel
                    </Button>
                    {step > 0 && <Button onClick={handleBack}>Back</Button>}
                    {step < validationSchemas.length - 1 ? (
                      <Button
                        onClick={() =>
                          handleNext(validateForm, setTouched, values)
                        }
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={loading}
                      >
                        {loading ? "Adding Child..." : "Add Child"}
                      </Button>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Fade>
      )}
    </Dialog>
  );
};

export default AddChildForm;
