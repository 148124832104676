import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import GetCalendarPrograms from "../../requests/getCalendarPrograms";
import { Box, Tooltip } from "@mui/material";
import CalendarSideBar from "./calendarSideBar";
import "./styles.css";

const Calendar = ({ sideBar = true }) => {
  const { currentEvents, currentCourses } = GetCalendarPrograms();
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipEl, setTooltipEl] = useState(null);

  useEffect(() => {
    setFilteredPrograms([...currentEvents, ...currentCourses]);
  }, [currentEvents, currentCourses]);

  console.log(currentEvents, currentCourses);

  const handleMouseEnter = (info) => {
    const { event, el } = info;
    const program = event._def.extendedProps;
    const start = formatTime(info.event.start);
    const end = formatTime(info.event.end);
    setTooltipData({
      title: (
        <>
          <strong style={{ fontSize: "0.8rem" }}>{program.name}</strong>
          <br />
          <span style={{ fontSize: "0.7rem" }}>
            Instructor: {program.instructor || "N/A"}
          </span>
          <br />
          <span style={{ fontSize: "0.7rem" }}>
            {start} - {end}
          </span>
        </>
      ),
      el: el,
    });
    setTooltipEl(el);
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
    setTooltipEl(null);
  };

  const handleEventContent = (args) => {
    const color = args.event._def.ui.borderColor;
    const start = formatTime(args.event.start);
    const end = formatTime(args.event.end);
    const title = args.event.title;
    const instructor = args.event._def.extendedProps.instructor;
    // console.log("args", args.event.start);

    if (args.event._context.viewApi.type === "dayGridMonth")
      return {
        html: `<div class="fc-daygrid-event-dot" style="border-color: ${color};"></div>
        <div class="fc-event-time">${start}</div>
        <div class="fc-event-title">${title}</div>`,
      };
    return {
      html: `<div style="overflow:hidden; height:inherit">
              <small>${start} - ${end}</small><br>
              <em>${title}</em><br>
              <small>Instructor: ${instructor}</small>
            </div>`,
    };
  };
  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        backgroundColor="#f2f0f0"
        p="20px 20px 20px 10px"
        borderRadius="15px"
        overflow="hidden"
      >
        {/* Calendar Sidebar */}
        {sideBar && (
          <CalendarSideBar
            currentCourses={currentCourses}
            currentEvents={currentEvents}
            setFilteredPrograms={setFilteredPrograms}
          />
        )}
        {/* FullCalendar with Tooltip Integration */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            height="80vh"
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek,dayGridMonth,timeGridDay,listMonth",
            }}
            initialView="timeGridWeek"
            scrollTime="09:00"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            events={filteredPrograms}
            eventMouseEnter={handleMouseEnter}
            eventMouseLeave={handleMouseLeave}
            eventContent={handleEventContent}
          />

          {/* Render Tooltip if tooltipData is set */}
          {tooltipData && tooltipEl && (
            <Tooltip
              title={tooltipData.title}
              placement="top"
              arrow
              open
              PopperProps={{
                anchorEl: tooltipEl,
                disablePortal: true,
              }}
              onClose={handleMouseLeave}
            >
              <div style={{ visibility: "hidden" }}>Tooltip</div>{" "}
              {/* Placeholder div to satisfy Tooltip's requirements */}
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Calendar;

const formatTime = (date) => {
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const timeString = new Date(date).toLocaleTimeString("en-US", options);
  console.log(timeString, typeof timeString);
  return timeString;
};
