import { capitalize } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

const apiURL = process.env.REACT_APP_API_URL;
const students = [];

const GetCalendarProgramsUser = () => {
  const [currentEvents, setCurrentEvents] = useState([]);
  const [currentCourses, setCurrentCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchAllPrograms = async () => {
    try {
      setLoading(true);
      const enrolledCourses = await axios.get(
        `${apiURL}/all-children-enrollments`,
        {
          withCredentials: true,
        }
      );
      const attendedEvents = await axios.get(`${apiURL}/all-children-events`, {
        withCredentials: true,
      });
      const formattedCourses = formatCourses(enrolledCourses.data);
      setCurrentCourses(formattedCourses);

      const formattedEvents = attendedEvents.data.map((event) => {
        students.push({
          id: event.student_id,
          fName: capitalize(event.sFirstName),
          lName: capitalize(event.sLastName),
          color: toDistinctHSLColor(event.student_id),
        });
        const newEvent = {
          ...event,
          title: event.name,
          groupId: "events",
          start: formatDate(event.date, event.time, 0),
          end: formatDate(event.date, event.time, 1),
          color: toDistinctHSLColor(event.student_id),
        };
        return newEvent;
      });
      setCurrentEvents(formattedEvents);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  useEffect(() => {
    fetchAllPrograms();
  }, []);

  return { currentEvents, currentCourses, error, loading, students };
};
export default GetCalendarProgramsUser;

const formatCourses = (courses) => {
  const formattedCourses = [];
  for (let i = 0; i < courses.length; i++) {
    const days = JSON.parse(courses[i].days);
    const timeRanges = JSON.parse(courses[i].time);
    students.push({
      id: courses[i].student_id,
      fName: capitalize(courses[i].sFirstName),
      lName: capitalize(courses[i].sLastName),
      color: toDistinctHSLColor(Math.abs(courses[i].student_id)),
    });
    for (let j = 0; j < days.length; j++) {
      console.log("day iteration");
      const courseGroupItem = {
        ...courses[i],
        title: courses[i].name,
        startTime: timeRanges[j].split("-")[0].trim(),
        endTime: timeRanges[j].split("-")[1].trim(),
        daysOfWeek: [days[j]],
        color: toDistinctHSLColor(Math.abs(courses[i].student_id)),
        groupId: courses[i].course_id,
        startRecur: courses[i].start,
        endRecur: courses[i].end,
      };
      console.log("item not pushed");
      formattedCourses.push(courseGroupItem);
      console.log("item pushed");
    }
  }
  console.log("formattedCourses", formattedCourses);
  return formattedCourses;
};

const toDistinctHSLColor = (id) => {
  const hue = (id * 25) % 360;
  const saturation = 70;
  const lightness = 50;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`; // Return HSL color
};

const formatDate = (date, time, index) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1; // Months are 0-indexed, so add 1
  const day = dateObj.getDate();
  const newDate = year + "-" + month + "-" + day + "T";
  const newTime = time.split("-")[index].trim();
  return newDate + newTime;
};
