import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Fade,
  DialogActions,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState, useEffect } from "react";
import GetChildren from "../../requests/getChildren";
import ChildrenBar from "../children/childBar";

const EnrolledModal = ({
  enrolledModalOpen,
  handleClosedEnrolledModal,
  courseID,
  booking,
  bookingConfirmed,
  bookingError,
  postEnrollChild,
}) => {
  const { data } = GetChildren();
  const [childArray, setChildArray] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (data && data.length) {
      const uniqueChildren = [];
      const seenNames = new Set();

      data.forEach((obj) => {
        const fullName = `${obj.first_name}-${obj.last_name}`;
        if (!seenNames.has(fullName)) {
          seenNames.add(fullName);
          uniqueChildren.push({
            id: obj.student_id,
            first_name: obj.first_name,
            last_name: obj.last_name,
            dob: new Date(obj.date_of_birth),
          });
        }
      });
      setChildArray(uniqueChildren);
    }
  }, [data]);

  useEffect(() => {
    console.log(bookingConfirmed);
  }, [bookingConfirmed]);

  return (
    <Dialog open={enrolledModalOpen} onClose={handleClosedEnrolledModal}>
      <DialogTitle textAlign="center">
        <img
          src="https://upstudio.africa/wp-content/uploads/2022/11/Logoinpill3-300x126.png"
          alt="logo"
          style={{
            width: "160px",
            height: "auto",
            padding: "20px",
            alignSelf: "center",
          }}
        />
      </DialogTitle>
      {bookingConfirmed ? (
        <Fade in={bookingConfirmed} timeout={600}>
          <DialogContent sx={{ padding: "4rem" }}>
            <Typography variant="h3" sx={{ textAlign: "center", mb: "20px" }}>
              Child registered!
            </Typography>
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="center">
                <CheckCircleIcon
                  style={{
                    color: "green",
                    fontSize: "10em",
                    textAlign: "center",
                  }}
                />
              </Box>
              <Box display="flex">
                <Typography
                  fontSize="1rem"
                  m="40px"
                  sx={{ textAlign: "center" }}
                >
                  Please check your inbox for a booking confirmation email.
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Fade>
      ) : (
        <Fade in={!bookingConfirmed} timeout={600}>
          <DialogContent sx={{ padding: "4rem", textAlign: "center" }}>
            <Typography variant="h4" sx={{ textAlign: "center", mb: "20px" }}>
              Please pick a child to enroll
            </Typography>
            <ChildrenBar childArray={childArray} setSelected={setSelected} />
            <Typography
              sx={{ textAlign: "center", m: "20px 0px", color: "red" }}
            >
              {bookingError}
            </Typography>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                onClick={async () => {
                  await postEnrollChild(courseID, selected.id);
                }}
                style={{ backgroundColor: "yellow" }}
              >
                {booking ? "Booking..." : "Book Now"}
              </Button>
            </DialogActions>
          </DialogContent>
        </Fade>
      )}
    </Dialog>
  );
};

export default EnrolledModal;
