import { Box, Typography, Snackbar, Alert } from "@mui/material";
import GetEnrolledCourses from "../../requests/getEnrolledCourses";
import GetEventsAttended from "../../requests/getEventsAttended";
import { React } from "react";
import { useAuth } from "../../Authentication/AuthContext";
import { usePreviousProgramsMenus } from "../students/addPreviousPrograms";

// Main Programs Component
const Programs = ({ selected }) => {
  console.log(selected);
  const { currentCourses, previousCourses } = GetEnrolledCourses({ selected });
  const { upcomingEvents, previousEvents } = GetEventsAttended({ selected });
  const { user } = useAuth();
  const {
    PreviousCourseMenu,
    PreviousEventsMenu,
    snackbarMessage,
    snackbarSeverity,
    snackbarOpen,
    setSnackbarOpen,
  } = usePreviousProgramsMenus();

  // Log to debug when snackbar opens
  console.log("Snackbar Open Status:", snackbarOpen);

  return (
    <Box>
      {/* UPCOMING PROGRAMS */}
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h3" color="#EF5340" mb={2}>
            UPCOMING PROGRAMS
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" width="100%">
          <Box display="flex" width="50%">
            {currentCourses.length > 0 ? (
              <CurrentPrograms data={currentCourses} />
            ) : (
              "No Upcoming Courses"
            )}
          </Box>
          <Box display="flex" width="50%">
            {upcomingEvents.length > 0 ? (
              <UpcomingEvents data={upcomingEvents} />
            ) : (
              "No upcoming Events!"
            )}
          </Box>
        </Box>
      </Box>

      <hr style={{ margin: "30px 0px" }} />

      {/* PREVIOUS COURSES */}
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" color="#EF5340">
            PREVIOUS COURSES
          </Typography>
          {user?.userType === "admin" && PreviousCourseMenu}
        </Box>
        <Box display="flex" flexDirection="row" width="100%">
          {previousCourses.length > 0 ? (
            <PreviousCourses data={previousCourses} type="COURSE" />
          ) : (
            <ul>
              <li>No previous courses</li>
            </ul>
          )}
        </Box>
      </Box>
      <hr style={{ margin: "30px 0px" }} />

      {/* PREVIOUS EVENTS */}
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h3" color="#EF5340">
            PREVIOUS EVENTS
          </Typography>
          {user?.userType === "admin" && PreviousEventsMenu}
        </Box>
        <Box display="flex" flexDirection="row" width="100%">
          {previousEvents.length > 0 ? (
            <PreviousEvents data={previousEvents} type="EVENT" />
          ) : (
            <ul>
              <li>No previous events</li>
            </ul>
          )}
        </Box>
      </Box>
      <hr style={{ margin: "30px 0px" }} />

      {/* PASSIONS  */}
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h3" color="#EF5340">
            PASSIONS
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" width="100%">
          <ul>
            <li>{selected.passion}</li>
          </ul>
        </Box>
      </Box>

      <hr style={{ margin: "30px 0px" }} />

      {/* DISABILITIES  */}
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h3" color="#EF5340">
            DISABILITIES
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" width="100%">
          <ul>
            <li>{selected.disabilities}</li>
          </ul>
        </Box>
      </Box>

      <hr style={{ margin: "30px 0px" }} />

      {/* PREFERENCES  */}
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h3" color="#EF5340">
            PREFERENCES
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" width="100%">
          <ul>
            <li>{selected.preferences}</li>
          </ul>
        </Box>
      </Box>
      <hr style={{ margin: "30px 0px" }} />

      {/* ALLERGIES  */}
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h3" color="#EF5340">
            ALLERGIES
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" width="100%">
          <ul>
            <li>{selected.allergies}</li>
          </ul>
        </Box>
      </Box>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Snackbar position
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Programs;

// Days of the week
const daysText = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// Function to format time
const formatDate = (timeRange) => {
  const [start, end] = timeRange.split("-");
  const to12hour = (time) => {
    let [hour, minute] = time.split(":").map(Number);
    const period = hour >= 12 ? "pm" : "am";
    const formattedHour = hour % 12 || 12; // Convert hour from 24h to 12h
    return `${formattedHour}:${minute.toString().padStart(2, "0")}${period}`;
  };
  const start12Hour = to12hour(start);
  const end12Hour = to12hour(end);
  return `${start12Hour} - ${end12Hour}`;
};

const CurrentPrograms = ({ data }) => {
  return (
    <Box display="flex" flexDirection="column" width="50%">
      {data.map((obj) => (
        <Box key={obj.id} mb={2}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {obj.name}{" "}
            {obj.paid === 0 && (
              <span
                style={{
                  color: "red",
                  fontWeight: "normal",
                  fontStyle: "italic",
                }}
              >
                {" "}
                (Payment Pending){" "}
              </span>
            )}
          </Typography>
          {obj.days.map((day, dayIndex) => (
            <Typography key={`${obj.id}-day-${dayIndex}`}>
              {daysText[day]}: {formatDate(obj.time[dayIndex])}
            </Typography>
          ))}
          <Typography>
            {obj.start} - {obj.end}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const PreviousCourses = ({ data }) => {
  const levels = [
    { group: 1, label: "Level 1: 4 - 6 Years" },
    { group: 2, label: "Level 2: 7 - 9 Years" },
    { group: 3, label: "Level 3: 10 - 15 Years" },
  ];

  return (
    <Box display="flex" width="100%" mt="15px">
      {levels.map(({ group, label }, index) => {
        const programs = data.filter((program) => program.group === group);

        return (
          <Box
            key={group}
            flex="1"
            p="5px"
            borderRight={index < levels.length - 1 ? "1px solid black" : "none"}
            paddingLeft={index > 0 ? "13px" : "0"}
          >
            <b>{label}</b>
            {programs.length > 0 ? (
              <ul>
                {programs.map(({ id, name, area }) => (
                  <li key={id}>{name}</li>
                ))}
              </ul>
            ) : (
              <ul>
                <li>No programs here</li>
              </ul>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

const PreviousEvents = ({ data, type }) => {
  return (
    <Box display="flex" flexDirection="column" width="50%" key={data.id}>
      <ul>
        {data.map((obj) => (
          <li key={obj.id}>{obj.name} </li>
        ))}
      </ul>
    </Box>
  );
};

const UpcomingEvents = ({ data }) => {
  return (
    <Box display="flex" flexDirection="column" width="50%">
      {data.map((obj) => (
        <Box key={obj.id} mb={2}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {obj.name}{" "}
            {obj.paid === 0 && (
              <span
                style={{
                  color: "red",
                  fontWeight: "normal",
                  fontStyle: "italic",
                }}
              >
                {" "}
                (Payment Pending){" "}
              </span>
            )}
          </Typography>
          <Typography>{obj.date}</Typography>
          <Typography>
            {new Date(obj.date).toLocaleDateString("en-GB", {
              weekday: "long",
            })}
            : {formatDate(obj.time)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
