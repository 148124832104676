import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useAuth } from "../../Authentication/AuthContext";
import LoginModal from "../home/loginModal";
import SignupModal from "../home/signupModal";
import { useModal } from "../../customHooks/useModal";
import EnrolledModal from "./enrolledModal.jsx";
import EnrollChild from "../../requests/postEnrollChild";
import TileModal from "./TileModal";

const CourseTiles = ({ filteredCourses }) => {
  const [enrolledModalOpen, setEnrolledModalOpen] = useState(false);
  const [courseID, setCourseID] = useState("");
  const [tileModalOpen, setTileModalOpen] = useState(false);
  const [course, setCourse] = useState("");
  const {
    booking,
    bookingConfirmed,
    bookingError,
    setBookingError,
    postEnrollChild,
    setBookingConfirmed,
  } = EnrollChild();

  const handleClosedEnrolledModal = () => {
    setBookingError(false);
    setBookingConfirmed(false);
    setCourseID("");
    setEnrolledModalOpen(false);
  };

  const {
    loginModalOpen,
    signupModalOpen,
    handleLoginCloseModal,
    handleSignupCloseModal,
    handleOpenSignupModal,
    handleLoginOpenModal,
  } = useModal();

  const { isAuthenticated } = useAuth();

  const handleBookNow = (courseID) => {
    if (!isAuthenticated) {
      handleLoginOpenModal();
    } else {
      setCourseID(courseID);
      setEnrolledModalOpen(true);
    }
  };

  const handleTileModalOpen = (course) => {
    setCourse(course);
    setTileModalOpen(true);
  };
  const handleTileModalClose = () => {
    setTileModalOpen(false);
    setBookingError(false);
    setBookingConfirmed(false);
  };

  return (
    <>
      {
        filteredCourses?.length
          ? filteredCourses.map((course) => (
              <Box
                key={course.course_id}
                sx={{
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <Box position="relative">
                  <img
                    src={
                      course.area.toLowerCase().includes("robotics")
                        ? `../../../assets/r.jpg`
                        : course.area.toLowerCase().includes("scientific")
                        ? `../../../assets/s.jpg`
                        : course.area.toLowerCase().includes("entre")
                        ? `../../../assets/e.jpg`
                        : course.area.toLowerCase().includes("creative")
                        ? `../../../assets/c.jpg`
                        : ""
                    }
                    alt={course.area}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <Typography
                    padding="0"
                    margin="0"
                    position="absolute"
                    top="5%"
                    right="5%"
                    backgroundColor="white"
                    p="5px"
                  >
                    TZS {course.price?.toLocaleString()}.00
                  </Typography>
                  <Typography
                    padding="0"
                    margin="0"
                    position="absolute"
                    bottom="2%"
                    right="5%"
                    backgroundColor="white"
                    p="5px"
                    sx={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                  >
                    #course
                  </Typography>
                </Box>
                <Box p={1}>
                  <Typography variant="h5" noWrap color="#ef5340">
                    {course.name}
                  </Typography>
                  <Typography variant="body2" noWrap fontWeight="bold">
                    {course.start.toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}{" "}
                    -{" "}
                    {course.end.toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </Typography>
                  <Typography variant="body2" noWrap>
                    {course.days}
                  </Typography>
                  <Typography variant="body2" noWrap>
                    {course.group.replace("-", " - ")}
                    <span
                      style={{
                        float: "right",
                        color: "#ef5340",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleTileModalOpen(course);
                      }}
                    >
                      View Details
                    </span>
                  </Typography>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px solid #d3d3d3",
                    }}
                  />
                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <img
                        src="https://upstudio.africa/wp-content/uploads/2022/11/favicon.png"
                        alt="img"
                        style={{
                          border: "2px solid #d3d3d3",
                          width: "40px",
                          borderRadius: "50%",
                          padding: "2px",
                        }}
                      />
                    </Box>
                    <Box>
                      <Button
                        sx={{
                          backgroundColor: "#FFD33D",
                          borderRadius: "30px",
                          color: "black",
                          fontWeight: "normal",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#FFC107",
                          },
                          margin: "2px",
                          textAlign: "right",
                          display: "block",
                          mx: "auto",
                          p: "0.5rem",
                        }}
                        onClick={() => {
                          handleBookNow(course.course_id);
                        }}
                      >
                        Book Now
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))
          : ""
        // (
        //   <Typography
        //     variant="body1"
        //     sx={{ gridColumn: "1 / -1", textAlign: "center" }}
        //   >
        //     No courses available
        //   </Typography>
        // )
      }
      <LoginModal
        modalOpen={loginModalOpen}
        handleCloseModal={handleLoginCloseModal}
        handleOpenSignupModal={handleOpenSignupModal}
      />
      <SignupModal
        modalOpen={signupModalOpen}
        handleCloseModal={handleSignupCloseModal}
      />
      <EnrolledModal
        enrolledModalOpen={enrolledModalOpen}
        handleClosedEnrolledModal={handleClosedEnrolledModal}
        courseID={courseID}
        booking={booking}
        bookingConfirmed={bookingConfirmed}
        bookingError={bookingError}
        postEnrollChild={postEnrollChild}
      />
      <TileModal
        tileModalOpen={tileModalOpen}
        handleTileModalClose={handleTileModalClose}
        program={course}
        postMethod={postEnrollChild}
        error={bookingError}
        fetching={booking}
        confirmed={bookingConfirmed}
        handleLoginOpenModal={handleLoginOpenModal}
        setTileModalOpen={setTileModalOpen}
      />
    </>
  );
};

export default CourseTiles;
