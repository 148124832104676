// StepThree.js
import React from "react";
import { TextField, Grid, Box, Typography } from "@mui/material";

const StepThree = ({ values, errors, touched, handleBlur, handleChange }) => (
  <Box>
    <Typography variant="h4" sx={{ textAlign: "center", mb: "20px" }}>
      Disabilities and Health Conditions
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography mb={1}>
          Does the learner have any learning or other cognitive disabilities or
          needs that we can consider for our programming?
        </Typography>
        <TextField
          fullWidth
          variant="filled"
          label="Learning or cognitive disabilities..."
          name="disabilities"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.disabilities}
          error={!!touched.disabilities && !!errors.disabilities}
          multiline
          helperText={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {touched.disabilities && errors.disabilities}
              <span>{values.disabilities.length} / 200 characters</span>
            </Box>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography mb={1}>
          Does the learner have any allergies or health conditions? If so, do
          they have an EpiPen or any other medication needed for immediate
          relief or action? Please provide as much information as you can,
          including a plan of action in case of emergencies that you would
          prefer
        </Typography>
        <TextField
          fullWidth
          variant="filled"
          label="Allergies and health condition..."
          name="allergies"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.allergies}
          error={!!touched.allergies && !!errors.allergies}
          multiline
          helperText={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {touched.allergies && errors.allergies}
              <span>{values.allergies.length} / 400 characters</span>
            </Box>
          }
        />
      </Grid>
    </Grid>
  </Box>
);

export default StepThree;
