import axios from "axios";
import { useState } from "react";

const apiURL = process.env.REACT_APP_API_URL;

const EnrollChild = () => {
  const [booking, setBooking] = useState(false);
  const [bookingConfirmed, setBookingConfirmed] = useState(false);
  const [bookingError, setBookingError] = useState("");

  const postEnrollChild = async (courseID, studentID) => {
    try {
      console.log("post method called on frontend", courseID, studentID);
      setBooking(true);
      const res = await axios.post(
        `${apiURL}/enroll-child`,
        { course_id: courseID, student_id: studentID },
        { withCredentials: true }
      );
      console.log(res);
      setBooking(false);
      setBookingConfirmed(true);
    } catch (e) {
      console.log(e);
      setBooking(false);
      setBookingConfirmed(false);
      if (e.response) {
        if (e.response.data?.errno === 1062) {
          setBookingError("Child is already booked to this program!");
        } else {
          setBookingError("Booking failed. Please try again later");
        }
      } else {
        setBookingError("Network error. Please check your connection.");
      }
    }
  };

  console.log(bookingConfirmed);

  return {
    booking,
    bookingConfirmed,
    bookingError,
    setBookingError,
    postEnrollChild,
    setBookingConfirmed,
  };
};

export default EnrollChild;
