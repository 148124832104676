import { Box, Grid, Button } from "@mui/material";

const Footer = () => {
  return (
    <Box sx={{ background: "gray" }}>
      <Grid
        container
        sx={{
          width: "100%",
          maxWidth: "96vw",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        {/* Logo */}
        <Grid item xs={6} md={4}>
          <Box>
            <img
              src="https://upstudio.africa/wp-content/uploads/2022/11/Logoinpill3-300x126.png"
              alt="logo"
              style={{ width: "140px", height: "auto", padding: "20px" }}
            />
          </Box>
        </Grid>

        {/* Buttons */}
        <Grid item xs={6} md={8}>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ overflowX: "auto" }}
          >
            <a href="http://facebook.com/upstudio.africa" target="_blank">
              <img
                width="30"
                height="30"
                src="https://upstudio.africa/wp-content/uploads/2024/02/6-Orange.png"
                className="attachment-full size-full wp-image-1897"
                alt=""
                style={{ margin: "0.5em" }}
              />
            </a>
            <a href="http://www.instagram.com/upstudio.africa" target="_blank">
              <img
                width="30"
                height="30"
                src="https://upstudio.africa/wp-content/uploads/2024/02/8-Orange.png"
                className="attachment-full size-full wp-image-1896"
                alt=""
                style={{ margin: "0.5em" }}
              />
            </a>
            <a href="https://twitter.com/UpStudioAfrica" target="_blank">
              <img
                width="30"
                height="30"
                src="https://upstudio.africa/wp-content/uploads/2024/02/5-Orange.png"
                className="attachment-large size-large wp-image-1898"
                alt=""
                style={{ margin: "0.5em" }}
              />
            </a>
            <a href="mailto:shama@upstudio.africa" target="_blank">
              <img
                width="30"
                height="30"
                src="https://upstudio.africa/wp-content/uploads/2024/02/3-Orange.png"
                className="attachment-large size-large wp-image-1899"
                alt=""
                style={{ margin: "0.5em" }}
              />
            </a>
            <a href="https://www.youtube.com/@upstudioafrica" target="_blank">
              <img
                width="30"
                height="30"
                src="https://upstudio.africa/wp-content/uploads/2024/02/upstudio-icons-youtube.webp"
                className="attachment-large size-large wp-image-1901"
                alt=" "
                style={{ margin: "0.5em" }}
              />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
