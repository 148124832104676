import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { capitalize } from "@mui/material";

const apiURL = process.env.REACT_APP_API_URL;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Check authentication on initial load
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const res = await axios.get(`${apiURL}/authorized`, {
          withCredentials: true,
        });
        if (res.data.auth) {
          console.log(res.data);
          let pathName = false;
          if (location.pathname !== "/home") {
            pathName = location.pathname;
          }
          setIsAuthenticated(true);
          setUser(res.data);
          if (!localStorage.getItem("user")) {
            const url = location.pathname.split("/");
            const user = url[url.length - 1];
            console.log(user);
            localStorage.setItem("user", capitalize(user.toLocaleLowerCase()));
            console.log("navigating");
            navigate("user/portal");
          } else if (location.pathname === "/") {
            navigate(
              res.data.userType === "user" ? "/user/portal" : "/dashboard"
            );
            return;
          } else {
            navigate(
              pathName ||
                (res.data.userType === "user" ? "/user/portal" : "/dashboard")
            );
          }
        }
      } catch (error) {
        console.error("Auth check failed", error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  const login = async (credentials) => {
    try {
      const response = await axios.post(`${apiURL}/login`, credentials, {
        withCredentials: true,
      });

      const { auth, d_name, role, userType } = response.data;

      if (auth) {
        setIsAuthenticated(true);
        if (d_name) localStorage.setItem("user", d_name);
        if (role) localStorage.setItem("role", role);

        if (userType === "user") {
          navigate("/user/portal/");
        } else if (userType === "admin") {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      console.error("Login failed", error);
      return error;
    }
  };

  const logout = async () => {
    try {
      // Clear local storage
      localStorage.removeItem("user");
      localStorage.removeItem("role");

      // Logout via the backend
      await axios.post(`${apiURL}/logout`, {}, { withCredentials: true });

      // Update local authentication state
      setIsAuthenticated(false);
      setUser(null);

      // Redirect to the home page and force a re-render
      navigate("/home");
      window.location.reload(); // Force a full reload of the page to clear any lingering state
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  useEffect(() => {
    console.log("isAuthenticated changed:", isAuthenticated);
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
