import { Box, Typography } from "@mui/material";
import GetParent from "../../requests/getParent";
import { useEffect } from "react";
import ParentDetailsBox from "./parentDetails/parentDetails";
import PasswordBox from "./changePassword/changePassword";

const Profile = () => {
  const { parent, error, fetchParent } = GetParent();

  useEffect(() => {
    fetchParent(); // Fetch parent data on component mount
  }, []);

  return (
    <Box
      m="40px 10px"
      display="grid"
      gridTemplateColumns={{
        xs: "1fr",
        sm: "repeat(3, 1fr)",
        md: "repeat(3, 1fr)",
      }}
      gap={2}
    >
      <Box m="10px" gridColumn="1/3">
        {parent.length > 0 && (
          <ParentDetailsBox parent={parent} fetchParent={fetchParent} /> // Use the styled component here
        )}
      </Box>
      <Box m="10px">
        {parent.length > 0 && (
          <PasswordBox parent={parent} /> // Use the styled component here
        )}
      </Box>
    </Box>
  );
};

export default Profile;
