import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import GetAllStudents from "../../requests/getAllStudents";

const Students = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { loading, students } = GetAllStudents();
  console.log(students);
  const columns = [
    { field: "student_id", headerName: "ID" },
    {
      field: "student_name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "date_of_birth", headerName: "Date of Birth", flex: 1 },
    {
      field: "parent_name",
      headerName: "Parent Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "parent_email",
      headerName: "Parent Email",
      flex: 1,
      cellClassName: "name-column--cell",
    },
  ];

  const handleCellClick = (params) => {
    console.log(params);
    params.field === "student_name" &&
      window.open(`/dashboard/students/${params.id}`);
  };

  return (
    <Box m="20px">
      <Header title="Students" subtitle="List of all Students" />
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeadersInner": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer": {
            color: colors.grey[100],
          },
          "& .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading ? (
          <Typography variant="h6" align="center">
            Loading...
          </Typography>
        ) : (
          <DataGrid
            rows={students}
            columns={columns}
            getRowId={(row) => row.student_id} // Ensures the DataGrid knows how to identify rows
            slots={{ toolbar: GridToolbar }}
            components={{ Toolbar: GridToolbar }}
            onCellClick={handleCellClick}
            density="compact"
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: {
                  variant: "outlined",
                },
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Students;
