import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";

const apiURL = process.env.REACT_APP_API_URL;

const EventForm = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const areas = [
    { value: "SCIENTIFIC EXPLORATION", label: "SCIENTIFIC EXPLORATION" },
    { value: "ROBOTICS AND CODING", label: "ROBOTICS AND CODING" },
    { value: "ENTREPRENEURSHIP", label: "ENTREPRENEURSHIP" },
    { value: "CREATIVE DESIGN", label: "CREATIVE DESIGN" },
  ];

  const group = [
    { value: "1", label: "4-6 Years" },
    { value: "2", label: "7-9 Years" },
    { value: "3", label: "10-15 Years" },
  ];
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values);
    const formattedData = {
      ...values,
      group: Number(values.group),
      price: Number(values.price),
      time: `${values.start} - ${values.end}`,
    };
    console.log(formattedData);

    try {
      const response = await axios.post(`${apiURL}/add-event`, formattedData, {
        withCredentials: true,
      });
      console.log("Event added successfully:", response.data);
      // Set success message and open snackbar
      setSnackbarMessage("Event added successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      resetForm(); // Reset the form fields to initial values
    } catch (e) {
      console.error("Error adding event:", e);
      // Set error message and open snackbar
      setSnackbarMessage("Error adding event. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const initialValues = {
    area: "",
    eventName: "",
    group: "",
    instructor: "",
    date: "",
    start: "",
    end: "",
    price: "",
    description: "",
  };

  // Regex for validating time format (24-hour format, e.g., "14:30")
  const timeRegExp = /^([01]\d|2[0-3]):([0-5]\d)$/;

  const checkoutSchema = yup.object().shape({
    area: yup.string().required("Area is required"),
    eventName: yup.string().required("Event is required"),
    group: yup.string().required("Age is required"),
    instructor: yup.string().required("Instructor is required"),
    date: yup
      .date()
      .required("Date is required")
      .typeError("Invalid date format"),
    start: yup
      .string()
      .matches(timeRegExp, "Start time must be in HH:MM format")
      .required("Start time is required"),
    end: yup
      .string()
      .matches(timeRegExp, "End time must be in HH:MM format")
      .required("End time is required"),
    price: yup.number().required("Price is required"),
    description: yup
      .string()
      .required("Description is required")
      .max(800, "Description must be 800 characters long"),
  });

  return (
    <>
      <Typography variant="h2" component="h2" align="center" mb="10px">
        Add Event
      </Typography>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm, // Destructure resetForm
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Event Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.eventName}
                name="eventName"
                error={!!touched.eventName && !!errors.eventName}
                helperText={touched.eventName && errors.eventName}
                sx={{ gridColumn: "span 4" }}
              />
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
                error={!!touched.area && !!errors.area}
              >
                <InputLabel>Area</InputLabel>
                <Select
                  name="area"
                  value={values.area}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Area"
                >
                  {areas.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{touched.area && errors.area}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
                error={!!touched.group && !!errors.group}
              >
                <InputLabel>Age Group</InputLabel>
                <Select
                  name="group"
                  value={values.group}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Group"
                >
                  {group.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{touched.group && errors.group}</FormHelperText>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name of Instructor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.instructor}
                name="instructor"
                error={!!touched.instructor && !!errors.instructor}
                helperText={touched.instructor && errors.instructor}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                name="price"
                error={!!touched.price && !!errors.price}
                helperText={touched.price && errors.price}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.date}
                name="date"
                error={!!touched.date && !!errors.date}
                helperText={touched.date && errors.date}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="time"
                label="Start Time"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.start}
                name="start"
                error={!!touched.start && !!errors.start}
                helperText={touched.start && errors.start}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="time"
                label="End Time"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.end}
                name="end"
                error={!!touched.end && !!errors.end}
                helperText={touched.end && errors.end}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Event Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
                multiline
              />
            </Box>
            <Box display="flex" justifyContent="center" mt="20px">
              <Button type="submit" variant="contained">
                Add Event
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Snackbar position
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EventForm;
