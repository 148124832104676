import { Box, Typography, Button, Snackbar, Alert } from "@mui/material";
import { useState } from "react";
import PasswordModal from "./passwordModal";
import KeyIcon from "@mui/icons-material/Key";
import ChangePassword from "../../../requests/updatePassword";

const PasswordBox = ({ parent }) => {
  const [open, setOpen] = useState(false);
  const { changePassword } = ChangePassword();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handlePasswordChange = async (currentPassword, newPassword) => {
    const result = await changePassword(currentPassword, newPassword);
    if (result.success) {
      setSnackbarMessage("Parent details updated successfully!");
      setSnackbarSeverity("success");
      setOpen(false);
    } else {
      console.log(result);
      setSnackbarMessage(result?.error?.message);
      setSnackbarSeverity("error");
    }
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box height="100%" p={3} bgcolor="#f9f9f9" borderRadius="8px" boxShadow={3}>
      <Box>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "#333", marginBottom: "5px" }}
        >
          Change Password
        </Typography>
      </Box>
      <Box>
        <KeyIcon sx={{ fontSize: "55px" }} />
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={() => setOpen(true)} // Open the modal
      >
        Edit
      </Button>

      {/* Modal Window */}
      <PasswordModal
        open={open}
        onClose={() => setOpen(false)} // Close the modal
        parent={parent} // Pass parent details to the dialog
        onPasswordChange={handlePasswordChange} // Pass save function
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PasswordBox;
