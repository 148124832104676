import { useState, useEffect } from "react";

const useFilter = (items, selectedDays, selectedGroup, programType) => {
  const [filteredItems, setFilteredItems] = useState([]);

  const daysText = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const daysMap = daysText.reduce(
    (map, day, index) => ({ ...map, [day]: index }),
    {}
  );

  useEffect(() => {
    const allItems = [...items].sort(
      (a, b) => new Date(a.start || a.date) - new Date(b.start || b.date)
    );

    // const formattedItems = allItems.map((item) => ({
    //   ...item,
    //   start: item.start ? item.start.toLocaleDateString() : false,
    //   end: item.end ? item.end.toLocaleDateString() : false,
    //   date: item.date ? item.date.toLocaleDateString() : false,
    // }));

    let filtered = allItems;

    if (selectedDays.length) {
      filtered = filtered.filter((item) =>
        selectedDays.some(
          (day) =>
            item.days?.includes(day) ||
            (item.date && item.date.getDay() === daysMap[day])
        )
      );
    }

    if (selectedGroup) {
      filtered = filtered.filter((item) => item.group === selectedGroup);
    }

    if (programType !== "all") {
      filtered = filtered.filter((item) => item.id === programType);
    }

    setFilteredItems(filtered);
  }, [items, selectedDays, selectedGroup, programType]);

  return filteredItems;
};

export default useFilter;
