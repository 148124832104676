import { useState, useEffect } from "react";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const GetAllPrograms = () => {
  const [previousEventsAdmin, setPreviousEvents] = useState([]);
  const [previousCoursesAdmin, setPreviousCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAllPrograms = async () => {
    try {
      const eventsResponse = await axios.get(`${apiURL}/previous-events`, {
        withCredentials: true,
      });
      const coursesResponse = await axios.get(`${apiURL}/previous-courses`, {
        withCredentials: true,
      });
      console.log(eventsResponse);
      console.log(coursesResponse);
      setPreviousCourses(coursesResponse.data);
      setPreviousEvents(eventsResponse.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPrograms();
  }, []);

  return {
    previousEventsAdmin,
    previousCoursesAdmin,
    loading,
    fetchAllPrograms,
  };
};

export default GetAllPrograms;
