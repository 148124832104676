import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";

function ModalWindow({ modalOpen, handleCloseModal, modalContent }) {
  let dayTimePairs;
  if (modalContent.time && modalContent.days) {
    const daysArray = modalContent.days.split(", ");
    const timesArray = JSON.parse(modalContent.time); // Assuming time is a JSON string
    dayTimePairs = daysArray.map((day, index) => {
      return `${day}: ${timesArray[index]}`;
    });
  }

  return (
    <Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      sx={{ width: "1000px", minWidth: "100%" }}
    >
      <DialogTitle>Event Details</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Days Offered:</Typography>
        {dayTimePairs &&
          dayTimePairs.map((pair, index) => (
            <Typography key={index} variant="body1" sx={{ pl: "15px" }}>
              {pair}
            </Typography>
          ))}
        <Typography variant="body1">
          Course Area: {modalContent.area}
        </Typography>
        <Typography variant="body1">
          Course Name: {modalContent.name}
        </Typography>
        <Typography variant="body1">
          Instructor: {modalContent.instructor}
        </Typography>
        <Typography variant="body1">
          Start Date: {modalContent.start?.toLocaleDateString()}
        </Typography>
        <Typography variant="body1">
          End Date: {modalContent.end?.toLocaleDateString()}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalWindow;
