import { Box, Typography, Button, Snackbar, Alert } from "@mui/material";
import { useState } from "react";
import ParentModal from "./parentModal";
import UpdateParent from "../../../requests/updateParent";

const ParentDetailsBox = ({ parent, fetchParent }) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const { updateParent } = UpdateParent();

  const handleSave = async (updatedParent) => {
    const result = await updateParent(updatedParent);
    if (result.success) {
      setSnackbarMessage("Parent details updated successfully!");
      setSnackbarSeverity("success");
      setOpen(false);
    } else {
      console.log(result);
      setSnackbarMessage(result?.error?.message);
      setSnackbarSeverity("error");
    }
    setSnackbarOpen(true);
    fetchParent();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box width="100%" p={3} bgcolor="#f9f9f9" borderRadius="8px" boxShadow={3}>
      <Box>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "#333", marginBottom: "5px" }}
        >
          Personal Details
        </Typography>
        <Typography variant="body1" sx={{ color: "#555", mb: 1 }}>
          <strong>Name:</strong> {parent[0].name}
        </Typography>
        <Typography variant="body1" sx={{ color: "#555", mb: 1 }}>
          <strong>Phone Number:</strong> {parent[0].phone_number}
        </Typography>
        <Typography variant="body1" sx={{ color: "#555" }}>
          <strong>Email:</strong> {parent[0].email}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={() => setOpen(true)}
      >
        Edit
      </Button>

      <ParentModal
        open={open}
        onClose={() => setOpen(false)}
        parent={parent}
        onSave={handleSave}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setSnackbarOpen={setSnackbarOpen}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ParentDetailsBox;
