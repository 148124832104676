import UserCalendar from "../userCalendar";
import Header from "../../components/Header";
import { Box } from "@mui/material";

const Schedule = () => {
  return (
    <Box m="20px">
      <Header title="My Schedule" subtitle="" />
      <UserCalendar Sidebar={false} calendarView="listWeek" />
    </Box>
  );
};

export default Schedule;
