import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import GetAllEnrollments from "../../requests/getAllEnrollments";
import PaidModal from "./paidModal.jsx";

const Enrollments = () => {
  const { enrollments, loading, fetchAllEnrollments } = GetAllEnrollments();
  const [paidModalOpen, setPaidModalOpen] = useState(false);
  const [enrollmentID, setEnrollmentID] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [paymentConfirmationLoading, setPaymentConfirmationLoading] =
    useState(false);
  const [error, setError] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "enrollment_id", headerName: "ID" },
    {
      field: "student_name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Course Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "start",
      headerName: "Course Start Date",
      type: "date",
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return new Date(params.value).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
        }
        return "";
      },
    },
    {
      field: "end",
      headerName: "Course End Date",
      flex: 1,
      type: "date",
      renderCell: (params) => {
        if (params.value) {
          return new Date(params.value).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
        }
        return "";
      },
    },
    {
      field: "enrollment_date",
      headerName: "Enrollment Date",
      flex: 1,
      type: "date",
      renderCell: (params) => {
        if (params.value) {
          return new Date(params.value).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
        }
        return "";
      },
    },
    {
      field: "paid",
      headerName: "Paid",
      flex: 1,
      renderCell: ({ row: { paid } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            mt="7px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={paid ? colors.greenAccent[600] : "red"}
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {paid ? "PAID" : "NOT PAID"}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const handleCellClick = (params) => {
    if (params.field === "paid" && params.row.paid === 0) {
      console.log(params);
      setPaidModalOpen(true);
      setEnrollmentID(params.id);
    }
  };

  const handleCloseModal = () => {
    setEnrollmentID("");
    setError("");
    setPaidModalOpen(false);
    setEmailSent(false);
  };

  useEffect(() => {
    emailSent && fetchAllEnrollments();
  }, [emailSent]);

  return (
    <Box m="20px">
      <Header title="Enrollments" subtitle="List of Enrollments members" />
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeadersInner": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer": {
            color: colors.grey[100],
          },
          "& .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading ? (
          <Typography variant="h6" align="center">
            Loading...
          </Typography>
        ) : (
          <DataGrid
            rows={enrollments}
            columns={columns}
            getRowId={(row) => row.enrollment_id}
            slots={{ toolbar: GridToolbar }}
            components={{ Toolbar: GridToolbar }}
            onCellClick={handleCellClick}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: {
                  variant: "outlined",
                },
              },
            }}
          />
        )}
      </Box>
      <PaidModal
        modalOpen={paidModalOpen}
        handleCloseModal={handleCloseModal}
        enrollmentID={enrollmentID}
        error={error}
        setError={setError}
        emailSent={emailSent}
        setEmailSent={setEmailSent}
        paymentConfirmationLoading={paymentConfirmationLoading}
        setPaymentConfirmationLoading={setPaymentConfirmationLoading}
      />
    </Box>
  );
};

export default Enrollments;
