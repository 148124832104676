import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { useEffect, useState, useMemo } from "react";

const CalendarSideBar = ({
  currentCourses,
  currentEvents,
  setFilteredPrograms,
  students,
  filteredPrograms,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const StudentsSet = new Set();
  students.forEach((student) => {
    StudentsSet.add(student.id);
  });
  const uniqueStudents = students.filter(
    (student) => StudentsSet.has(student.id) && StudentsSet.delete(student.id)
  );

  useEffect(() => {
    setFilteredCourses(currentCourses);
    setFilteredEvents(currentEvents);
    console.log(currentEvents);
    console.log(currentCourses);
  }, [currentCourses, currentEvents]);

  useEffect(() => {
    setFilteredPrograms([...filteredEvents, ...filteredCourses]);
  }, [filteredCourses, filteredEvents]);

  const handleEventClick = (id) => {
    if (filteredEvents.find((event) => event.student_id === id)) {
      setFilteredEvents(
        filteredEvents.filter((event) => event.student_id !== id)
      );
    } else {
      const found = currentEvents.filter((event) => event.student_id === id);
      if (found) setFilteredEvents((prev) => [...prev, ...found]);
    }
    if (filteredCourses.find((course) => course.student_id === id)) {
      setFilteredCourses(
        filteredCourses.filter((course) => course.student_id !== id)
      );
    } else {
      const found = currentCourses.filter((course) => course.student_id === id);
      if (found) setFilteredCourses((prev) => [...prev, ...found]);
    }
  };

  return (
    <Box
      flex="1 1 30%"
      backgroundColor={colors.primary[400]}
      p="15px"
      borderRadius="4px"
      height="80vh"
      sx={{ overflowY: "scroll", scrollbarWidth: "thin" }}
    >
      <Typography variant="h2">My Children</Typography>
      <List>
        {uniqueStudents.map((student) => (
          <ListItem
            key={student.student_id}
            sx={{
              backgroundColor: filteredPrograms.some(
                (s) => s.student_id === student.id
              )
                ? student.color
                : "gray",
              margin: "10px 0",
              borderRadius: "2px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleEventClick(student.id);
            }}
          >
            <ListItemText
              primary={student.fName + " " + student.lName}
              // secondary={<Typography>{student.fName}</Typography>}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CalendarSideBar;

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
