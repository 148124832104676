import React from "react";
import {
  Grid,
  Box,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Link,
} from "@mui/material";

const StepFour = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
}) => (
  <Box>
    <Typography variant="h4" sx={{ textAlign: "center", mb: "20px" }}>
      Terms and Conditions
    </Typography>
    <Grid container spacing={3}>
      {/* Radio buttons for pictures */}
      <Grid item xs={12}>
        <Typography>
          UpStudio Africa is allowed to take pictures and videos of learners and
          parents during Studios for publications, promotions, and other
          marketing-related platforms.
        </Typography>
        <FormControl
          component="fieldset"
          error={touched.pictures && !!errors.pictures}
        >
          <RadioGroup
            name="pictures"
            value={values.pictures}
            onChange={handleChange}
            onBlur={handleBlur}
            row
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          {touched.pictures && errors.pictures && (
            <Typography color="error">{errors.pictures}</Typography>
          )}
        </FormControl>
      </Grid>

      {/* Terms and Conditions checkbox */}
      <Grid item xs={12}>
        <Typography>
          We want all children to have a fun and great experience at UpStudio
          Africa. We have some terms & conditions to protect you and your
          children, as well as protect us. These terms and conditions were
          updated on 31 January 2024.
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.termsAccepted}
              onChange={(e) => setFieldValue("termsAccepted", e.target.checked)}
              onBlur={handleBlur}
              name="termsAccepted"
            />
          }
          label={
            <>
              I have read and accepted the{" "}
              <Link
                href="/path/to/terms.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </Link>
            </>
          }
        />
        {touched.termsAccepted && errors.termsAccepted && (
          <Typography color="error">{errors.termsAccepted}</Typography>
        )}
      </Grid>
    </Grid>
  </Box>
);

export default StepFour;
