import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "./AuthContext";

function Logout() {
  const { logout } = useAuth();
  logout();
  return null;
}

export default Logout;
