import { Routes, Route } from "react-router-dom";
import Login from "./Authentication/login";
import Admin from "./admin"; // Ensure this is the correct path for your dashboard
import { AuthProvider } from "./Authentication/AuthContext";
import ProtectedRoute from "./Authentication/ProtectedRoute";
import Home from "./scenes/home";
import FAQ from "./scenes/faq";
import UserDashboard from "./user";

function App() {
  return (
    <AuthProvider>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/faq" element={<FAQ />} />
          <Route
            path="/dashboard/*"
            element={<ProtectedRoute element={<Admin />} />}
          />
          <Route
            path="/user/portal/*"
            element={<ProtectedRoute element={<UserDashboard />} />}
          />
        </Routes>
      </main>
    </AuthProvider>
  );
}

export default App;
