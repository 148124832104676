import { useState } from "react";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const GetStudent = () => {
  const [student, setStudent] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [studentID, setStudentID] = useState("");

  const fetchData = async (id) => {
    try {
      const res = await axios.get(`${apiURL}/student?student_id=${id}`, {
        withCredentials: true,
      });
      console.log("request sent to server");
      const formattedData = res.data.map((student) => ({
        ...student,
        dob: new Date(student.date_of_birth),
        id: student.student_id,
      }));
      setStudent(formattedData); // Set the fetched data
      console.log(formattedData);
      setError(null); // Clear any previous errors
    } catch (e) {
      console.error(e);
      setError("Failed to fetch data"); // Set an error message
    } finally {
      setLoading(false); // Set loading to false when the request is complete
    }
  };

  return { student, loading, error, setStudentID, studentID, fetchData }; // Return data, loading, and error states
};

export default GetStudent;
