import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import NavBar from "../global/NavBar";
import Footer from "../global/footer";
import { useAuth } from "../../Authentication/AuthContext";

export default function FAQ() {
  const { isAuthenticated } = useAuth();
  return (
    <Box>
      {/* NavBar */}
      {isAuthenticated ? (
        ""
      ) : (
        <Box>
          <NavBar />
        </Box>
      )}
      {/* Accordions */}
      <Box p="50px">
        <Typography variant="h4" pb="20px">
          Welcome to UpStudio FAQs
        </Typography>
        {/* General Information */}
        <Box>
          <Typography color="#EF5340" variant="h6" pb="10px">
            {" "}
            General Information
          </Typography>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                What is UpStudio Africa?
              </AccordionSummary>
              <AccordionDetails>
                UpStudio Africa is the leading creator of innovative learning
                experiences in Tanzania. We envision a world where learning
                opportunities in science, technology, entrepreneurship, and
                creative design are accessible to all. Through experiential
                learning and collaborative projects, UpStudio Africa empowers
                learners to become the innovators of tomorrow. UpStudio Africa
                has reached over 600 students across 50 schools in Dar es
                Salaam. For more information, please visit our website:{" "}
                <a href="https://upstudio.africa/" target="_blank">
                  www.upstudio.africa
                </a>
              </AccordionDetails>
            </Accordion>
            {/* <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Accordion Actions
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
              <AccordionActions>
                <Button>Cancel</Button>
                <Button>Agree</Button>
              </AccordionActions>
            </Accordion> */}
          </div>
        </Box>
        {/* Sign Up Process */}
        <Box>
          <Typography color="#EF5340" variant="h6" pb="10px" pt="20px">
            {" "}
            Signing Up Process
          </Typography>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                How do I sign up for the booking platform?
              </AccordionSummary>
              <AccordionDetails>
                Click on sign up on the top left of the Booking Platform’s
                landing page. The sign up page acts as an application form
                needed to join any programs at UpStudio Africa.
                <br />
                <br />
                You will be required to submit the following information:
                <ul>
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Parent Information:
                    </span>{" "}
                    This serves as your log-in details for the booking platform
                    as well as our emergency contact.
                  </li>
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Children(s) Information:
                    </span>{" "}
                    You will be asked to include information on each child which
                    includes the date of birth, current school, learning
                    preferences, special learning needs, and health and
                    allergies specifications.
                  </li>
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Communication Clearances:
                    </span>{" "}
                    We require parents to provide approval for us to use their
                    child/children’s photos and videos taken during any of our
                    programs for our general communication purposes, including
                    social media, newspapers, WhatsApp and other relevant
                    platforms.
                  </li>
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Terms and Conditions:
                    </span>{" "}
                    We require that you agree to the terms and conditions. Our
                    terms and conditions can be found on our website: <br />
                    <a href="https://upstudio.africa/" target="_blank">
                      www.upstudio.africa
                    </a>{" "}
                    Please review them thoroughly before completing the
                    application form and booking any program.
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                What do I do once I sign up for the booking platform?
              </AccordionSummary>
              <AccordionDetails>
                You will be required to log-in to the booking platform to book a
                program for a specific child. Please save your log-in details
                (email address and password) so that you are able to access the
                booking platform in the future.
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
        {/* Booking Process */}
        <Box>
          <Typography color="#EF5340" variant="h6" pb="10px" pt="20px">
            {" "}
            Signing Up Process
          </Typography>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                How do I book a course or event for my child?
              </AccordionSummary>
              <AccordionDetails>
                Step-by-step instructions on how to book.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                What information do I need to provide during booking?
              </AccordionSummary>
              <AccordionDetails>
                Once you sign-up for the booking platform, you do not need to
                provide any additional information for the child. If you have
                multiple children, ensure you select the correct child for the
                program you are booking.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Can I book multiple courses/events at once?
              </AccordionSummary>
              <AccordionDetails>
                There is currently no option to book multiple courses or events
                at once. You will be required to book each course or event
                separately.
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
        {/* Payment and Fees */}
        <Box>
          <Typography color="#EF5340" variant="h6" pb="10px" pt="20px">
            {" "}
            Payment and Fees
          </Typography>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                What are the payment methods accepted?
              </AccordionSummary>
              <AccordionDetails>
                The platform does not have an integrated payment solution built
                into it as yet. All payments will need to be made off the
                booking platform. Please note: We do not accept cash payments at
                UpStudio Africa. We accept bank, mobile money, and card
                payments. Once payment is made using below payment methods,
                please share a payment confirmation with us for verification.
                See all payment details below: <br />
                <br />
                {/* Bank Transfer */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Bank Transfer
                  </AccordionSummary>
                  <AccordionDetails>
                    Account Name: UPSTUDIO AFRICA <br />
                    Account No.: TZS 0205735001 <br />
                    Bank Name: Diamond Trust Bank Tanzania Limited <br />
                    Branch Name: Masaki Branch <br />
                    Branch Code: 015 <br />
                    Swift Code: DTKETZTZ <br />
                  </AccordionDetails>
                </Accordion>
                {/* Selcom */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Selcom
                  </AccordionSummary>
                  <AccordionDetails>
                    Merchant Name: UpStudio Africa <br />
                    Account Number: 6058 6478
                  </AccordionDetails>
                </Accordion>
                {/* M-Pesa */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Lipa Namba - MPesa
                  </AccordionSummary>
                  <AccordionDetails>
                    Merchant Name: UpStudio Africa <br />
                    Account Number: 5278867
                  </AccordionDetails>
                </Accordion>
                {/* Card */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Card
                  </AccordionSummary>
                  <AccordionDetails>
                    We accept card payments at UpStudio Africa. We charge a 2%
                    processing fee on this transaction. Card payments can be
                    made through a POS machine at UpStudio Africa or through the
                    link:{" "}
                    <a href="https://selcompay.me/60586478" target="_blank">
                      https://selcompay.me/60586478
                    </a>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                What are the payment terms and conditions?
              </AccordionSummary>
              <AccordionDetails>
                Payments made after the initial 15 days of the term or the first
                2 days of camp will incur a 10% penalty unless approved payment
                terms and plans have been communicated and approved by
                UpStudio’s administrative team.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Can I get a refund if I cancel my booking?
              </AccordionSummary>
              <AccordionDetails>
                Our refund policy can be found in our terms and conditions:{" "}
                <a href=" https://upstudio.africa/terms." target="_blank">
                  https://upstudio.africa/terms.
                </a>
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
        {/* Course/Event Details */}
        <Box>
          <Typography color="#EF5340" variant="h6" pb="10px" pt="20px">
            {" "}
            Course/Event Details
          </Typography>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                What courses/events are currently available?
              </AccordionSummary>
              <AccordionDetails>
                All our upcoming programs have been updated on our booking
                system. This includes term dates, days of the program, as well
                as course description. Please visit our booking platform for all
                relevant information. Our calendar for the year can be found
                here: www.upstudio.africa/calendar
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                How do I know if a course/event is suitable for my child?
              </AccordionSummary>
              <AccordionDetails>
                We recommend selecting programs that align with your child's age
                and areas of interest. Encouraging students to explore new and
                cross-disciplinary topics can expand their thinking and exposure
                across various sectors. This versatility and agility will be
                valuable for the jobs and opportunities that will emerge in the
                next 10-20 years. For students currently enrolled in our
                programs, we are happy to recommend programs that may be
                suitable for them to help them continue their journey at
                UpStudio Africa. <br />
                <br />
                Each program at UpStudio Africa has been curated for specific
                age levels: (i) Pre-Level 1 (3 - 4 years), (ii) Level 1 (4 - 6
                years), (iii) Level 2 (7 - 9 years), and (iv) Level 4 (10 - 15
                years). There are other programs that cut across multiple age
                levels - this will be noted in the course description
                accordingly. The programs are also categorized in four topic
                areas: (i) Science Exploration, (ii) Robotics and Coding, (iii)
                Entrepreneurship, and (iv) Creative Design. If you still have
                questions, you can contact us via WhatsApp at +255 716 476
                212.For children turning 4 years old or those who have recently
                turned 4, UpStudio Africa requires a 20-minute assessment before
                finalizing their admission and requesting an application. A
                final decision on the assessment may be delivered within 48
                hours. UpStudio Africa reserves the right to delay admission and
                will provide a timeline for reassessing students’ readiness to
                join UpStudio. <br />
                <br />
                For children enrolling in our Pre-Level 1 program, turning 4
                years old or those who have recently turned 4 who want to join
                our Level 1 programs, UpStudio Africa requires a 20-minute
                assessment before finalizing their admission and requesting an
                application. A final decision on the assessment may be delivered
                within 48 hours. UpStudio Africa reserves the right to delay
                admission and will provide a timeline for reassessing
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
        {/* Attendance and Participation */}
        <Box>
          <Typography color="#EF5340" variant="h6" pb="10px" pt="20px">
            {" "}
            Attendance and Participation
          </Typography>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                What should my child bring to the course/event?
              </AccordionSummary>
              <AccordionDetails>
                Students are required to carry a reusable water bottle to class
                at all times. The allowance for snacks will be communicated to
                parents and/or guardians ahead of time and may be limited to
                longer sessions, such as camps or expeditions. Beyond that
                specific requirements (such as bringing personal laptops or
                location changes for specific days) will be communicated in the
                course description or ahead of time.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Is there a dress code?
              </AccordionSummary>
              <AccordionDetails>
                There is no specific dress code for students at UpStudio Africa.
                Students are required to dress comfortably that will allow them
                to effectively engage in active teaching and learning. For
                programs hosted at partner organizations, we recommend students
                to dress well and respectably.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Can parents stay during the course/event?
              </AccordionSummary>
              <AccordionDetails>
                Parents, guardians, and/or caretakers will not be permitted
                inside the classroom during the sessions. Students under the age
                of 4 years or those attending UpStudio for the first time may
                have a caretaker for the first class. UpStudio will review these
                requests 24 hours ahead of the sessions and reserves the right
                to make the final decision. Specific events may require parent
                participation. This will be communicated to parents ahead of
                time.
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
        {/* Technical Support */}
        <Box>
          <Typography color="#EF5340" variant="h6" pb="10px" pt="20px">
            {" "}
            Technical Support
          </Typography>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Who do I contact if I have issues with the booking platform?
              </AccordionSummary>
              <AccordionDetails>
                Please contact{" "}
                <a href="mailto:admin@upstudio.africa">admin@upstudio.africa</a>{" "}
                for any questions or concerns regarding the booking system. We
                are also available on WhatsApp at +255 716 476 212.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                What should I do if I don't receive a confirmation email?
              </AccordionSummary>
              <AccordionDetails>
                If you do not receive a confirmation email upon (i) signing up
                for the booking platform, and (ii) booking of a course or event,
                please contact us at WhatsApp at +255 716 476 212.
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
        {/* Special Needs */}
        <Box>
          <Typography color="#EF5340" variant="h6" pb="10px" pt="20px">
            {" "}
            Technical Support
          </Typography>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Does UpStudio Africa accommodate children with special needs?
              </AccordionSummary>
              <AccordionDetails>
                At UpStudio Africa, we strive to accommodate students with a
                diverse range of special needs. For students with learning
                needs, we ask parents to disclose this information in the
                application form and recommend an assessment before enrollment.
                This allows us to have honest and open discussions with parents
                about our capacity and how we can best support their child. For
                students with physical needs, our facility is accessible via the
                underground parking area, with an elevator to the ground floor
                where UpStudio Africa is located. However, please note that the
                ground floor access does not have ramps. We are committed to
                creating an inclusive environment and will do our utmost to
                ensure every child can participate meaningfully in our programs.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                How can I request special accommodations for my child?
              </AccordionSummary>
              <AccordionDetails>
                All special accommodations for your child need to be submitted
                via the application form on the booking platform. If there are
                any additional specifications that you would like to communicate
                with us, please feel free to contact us via WhatsApp at +255 716
                476 212.
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
        {/* Contact Information */}
        <Box>
          <Typography color="#EF5340" variant="h6" pb="10px" pt="20px">
            {" "}
            Contact Information
          </Typography>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Who can I contact for more information about a specific
                course/event?
              </AccordionSummary>
              <AccordionDetails>
                Please review our catalog for more information about specific
                courses. If you still have questions, you can contact us via
                WhatsApp at +255 716 476 212.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                How can I request special accommodations for my child?
              </AccordionSummary>
              <AccordionDetails>
                All special accommodations for your child need to be submitted
                via the application form on the booking platform. If there are
                any additional specifications that you would like to communicate
                with us, please feel free to contact us via WhatsApp at +255 716
                476 212.
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
      </Box>
      {/* Footer */}
      {isAuthenticated ? (
        ""
      ) : (
        <Box>
          <Footer />
        </Box>
      )}
    </Box>
  );
}
