import React, { useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  Fade,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useAuth } from "../../Authentication/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const initialValues = {
  firstname: "",
  lastname: "",
  password: "",
  password2: "",
  phoneNo: "",
  email: "",
};

const apiURL = process.env.REACT_APP_API_URL;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const userSchema = yup.object().shape({
  firstname: yup.string().required("Required field"),
  lastname: yup.string().required("Required field"),
  password: yup
    .string()
    .required("Required field")
    .min(8, "Password must be at least 8 characters long"),
  password2: yup
    .string()
    .required("Required field")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  phoneNo: yup
    .string()
    .required("Required field")
    .matches(phoneRegExp, "Phone number is not valid"),
  email: yup.string().trim().required("Required field").email("Invalid email"),
});

const SignupModal = ({ modalOpen, handleCloseModal, handleLoginOpenModal }) => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    setError(""); // Reset previous error messages
    try {
      const res = await axios.post(`${apiURL}/email-verification`, values, {
        withCredentials: true,
      });
      console.log("Server response:", res.status);
      setEmailSent(true);
    } catch (e) {
      console.error("Failed to insert user:", e);
      setError(e.response?.data?.message || "An unexpected error occurred.");
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      sx={{
        minWidth: "100%",
        alignSelf: "center",
        height: "100vh",
      }}
    >
      <DialogTitle textAlign="center">
        <img
          src="https://upstudio.africa/wp-content/uploads/2022/11/Logoinpill3-300x126.png"
          alt="logo"
          style={{
            width: "8em",
            height: "auto",
            alignSelf: "center",
          }}
        />
      </DialogTitle>
      {emailSent ? (
        <Fade in={emailSent} timeout={600}>
          <DialogContent sx={{ padding: "4rem" }}>
            <Typography variant="h4" sx={{ textAlign: "center", mb: "1em" }}>
              Email Sent!
            </Typography>
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="center">
                <CheckCircleIcon
                  style={{
                    color: "green",
                    fontSize: "10em",
                    textAlign: "center",
                  }}
                />
              </Box>
              <Box display="flex">
                <Typography
                  fontSize="1rem"
                  m="1em"
                  sx={{ textAlign: "center" }}
                >
                  Please check your inbox so you can verify your email.
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Fade>
      ) : (
        <Fade in={!emailSent} timeout={600}>
          <DialogContent sx={{ padding: "4em" }}>
            <Box>
              <Typography variant="h4" sx={{ textAlign: "center", mb: "1em" }}>
                Sign up
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={userSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display="grid"
                      gap="1em"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    >
                      <TextField
                        fullWidth
                        variant="filled"
                        label="First Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstname}
                        name="firstname"
                        error={!!touched.firstname && !!errors.firstname}
                        helperText={touched.firstname && errors.firstname}
                        sx={{ gridColumn: "span 6" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        label="Last Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastname}
                        name="lastname"
                        error={!!touched.lastname && !!errors.lastname}
                        helperText={touched.lastname && errors.lastname}
                        sx={{ gridColumn: "span 6" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        label="Email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        error={(!!touched.email && !!errors.email) || error}
                        helperText={touched.email && errors.email}
                        sx={{ gridColumn: "span 6" }}
                      />

                      <TextField
                        fullWidth
                        variant="filled"
                        label="Phone No."
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phoneNo}
                        name="phoneNo"
                        error={!!touched.phoneNo && !!errors.phoneNo}
                        helperText={touched.phoneNo && errors.phoneNo}
                        sx={{ gridColumn: "span 6" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        label="Set Password"
                        type="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                        sx={{ gridColumn: "span 12" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        label="Repeat Password"
                        type="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password2}
                        name="password2"
                        error={!!touched.password2 && !!errors.password2}
                        helperText={touched.password2 && errors.password2}
                        sx={{ gridColumn: "span 12" }}
                      />
                    </Box>
                    <Box mt="2em">
                      {error && (
                        <Typography
                          color="error"
                          fontSize="1rem"
                          mt="1em"
                          sx={{ textAlign: "center" }}
                        >
                          {error}
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex" justifyContent="center" mt="2em">
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={loading}
                        sx={{
                          backgroundColor: "#bc1a1a",
                          width: "47%",
                          padding: "1em 0em",
                        }}
                      >
                        {loading ? "Creating Account..." : "Create Account"}
                      </Button>
                    </Box>
                    <Box display="flex" justifyContent="center" mt="2em">
                      <Typography variant="body2" sx={{ textAlign: "center" }}>
                        Already have an account?
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Button
                        onClick={() => {
                          handleCloseModal();
                          handleLoginOpenModal();
                        }}
                      >
                        Log In
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Fade>
      )}
    </Dialog>
  );
};

export default SignupModal;
