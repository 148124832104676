import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import EventTiles from "./eventTiles";
import CourseTiles from "./courseTiles";
import { Box } from "@mui/material";
import req from "./req";
import useFilter from "./useFilter";

const apiURL = process.env.REACT_APP_API_URL;
const daysText = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const Tiles = ({ selectedDays, selectedGroup, programType }) => {
  const [events, setEvents] = useState([]);
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [eventsRes, coursesRes] = await Promise.all([
          axios.get(`${apiURL}/events`, { withCredentials: true }),
          axios.get(`${apiURL}/courses`, { withCredentials: true }),
        ]);

        const formatItem = (item, type) => ({
          ...item,
          start: type === "course" ? new Date(item.start) : undefined,
          end: type === "course" ? new Date(item.end) : undefined,
          date: type === "event" ? new Date(item.date) : undefined,
          group:
            item.group === 1
              ? "Level 1: 4-6 years"
              : item.group === 2
              ? "Level 2: 7-9 years"
              : "Level 3: 10-15 years",
          days:
            type === "course"
              ? JSON.parse(item.days)
                  .map((day) => daysText[day])
                  .join(", ")
              : undefined,
          id: type,
        });

        setEvents(eventsRes.data.map((item) => formatItem(item, "event")));
        setCourses(coursesRes.data.map((item) => formatItem(item, "course")));
      } catch (e) {
        setError(e);
      }
    };

    fetchData();
  }, []);

  if (error) {
    console.log(error);
  }

  const allPrograms = useMemo(() => [...events, ...courses], [events, courses]);

  const filteredItems = useFilter(
    allPrograms,
    selectedDays,
    selectedGroup,
    programType
  );

  return (
    <Box>
      <Box
        display="grid"
        gap={{ xs: "0.5rem", sm: "1rem", md: "1.5rem" }}
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        }}
        padding={{ xs: "1%", sm: "2%" }}
        gridAutoFlow="dense"
        width="100%"
      >
        {filteredItems.length && (
          <>
            {/* Render event tiles */}
            <EventTiles
              filteredEvents={filteredItems.filter(
                (event) => event.id === "event"
              )}
            />

            {/* Render course tiles */}
            <CourseTiles
              filteredCourses={filteredItems.filter(
                (course) => course.id === "course"
              )}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Tiles;
