import { useState, useEffect } from "react";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const GetEnrolledCourses = ({ selected }) => {
  const [data, setData] = useState([]);
  const [currentCourses, setCurrentCourses] = useState([]); // Initialize with an empty array
  const [previousCourses, setPreviousCourses] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const currentItems = [];
  const previousItems = [];

  useEffect(() => {
    if (!selected || !selected.id) return;
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${apiURL}/enrolled-courses`,
          { id: selected.id },
          {
            withCredentials: true,
          }
        );

        const formattedData = response.data.map((dataObj) => ({
          ...dataObj,
          id: dataObj.course_id,
          days: JSON.parse(dataObj.days),
          time: JSON.parse(dataObj.time),
          start: new Date(dataObj.start).toLocaleDateString(),
          area: dataObj.area,
          group: dataObj.group,
        }));
        formattedData.forEach((obj) => {
          new Date(obj.end) > new Date()
            ? currentItems.push({
                ...obj,
                end: new Date(obj.end).toLocaleDateString(),
              })
            : previousItems.push({
                ...obj,
                end: new Date(obj.end).toLocaleDateString(),
              });
        });
        // setting values
        setCurrentCourses(currentItems);
        setPreviousCourses(previousItems);
        setData(formattedData);
      } catch (e) {
        console.error(e);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selected]);

  return { data, currentCourses, previousCourses, loading, error };
};

export default GetEnrolledCourses;
