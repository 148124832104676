import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import ChildHeader from "../children/childHeader";
import Programs from "../children/programs";
import GetStudent from "../../requests/getStudent";
import GetAllPrograms from "../../requests/getPreviousPrograms";

const Student = () => {
  const { snackbarOpen } = GetAllPrograms();
  const [selected, setSelected] = useState({});
  const { student, loading, error, setStudentID, studentID, fetchData } =
    GetStudent();
  const pathSegments = window.location.pathname.split("/");
  const student_id = pathSegments[pathSegments.length - 1];

  // Effect to fetch data based on student ID from URL
  useEffect(() => {
    if (student_id) {
      setStudentID(student_id); // Set the student ID state
      fetchData(student_id); // Fetch the student data
    }
  }, []); // Ensure fetchData is a dependency

  // Effect to set selected student once student data is available
  useEffect(() => {
    if (studentID && student.length > 0) {
      console.log("set selected use Effect hook called");
      setSelected(student[0]);
    }
  }, [student, studentID]);

  console.log("selected", selected.id);
  student && console.log("student from student", student);

  return (
    <Box>
      <Box>
        <hr />
      </Box>
      {/* Header  */}
      {loading ? (
        "Loading..."
      ) : error ? (
        <div>Error loading student data: {error}</div> // Display error message
      ) : (
        <>
          <ChildHeader selected={selected} />
          <hr style={{ margin: "0px 30px" }} />
          <Box m="30px">
            <Programs selected={selected} />
          </Box>
          <hr style={{ margin: "0px 30px" }} />
        </>
      )}
    </Box>
  );
};

export default Student;
