import { Box, Typography, Button } from "@mui/material";

const Landing = () => {
  return (
    <Box display="flex" flexDirection="row">
      {/* Left Column */}
      <Box width="40vw" padding="25px" margin="0" alignContent="center">
        <Typography variant="h4">Book your</Typography>
        <Typography variant="h2" color="#EF5340">
          Programs
        </Typography>
        <Typography variant="p" sx={{ fontSize: "25px" }}>
          UpStudio Africa has a range of courses, camps, and events coming up
          for 4 - 15 year old innovators!
        </Typography>
        <Box mt="25px">
          <Button
            sx={{
              backgroundColor: "#FFD33D",
              borderRadius: "30px",
              color: "black",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#FFC107", // Slightly darker yellow on hover
              },
            }}
            href="#next"
          >
            <img
              src="../../../assets/rocket.png"
              alt="pic"
              style={{ padding: "3px" }}
            />
            &nbsp;&nbsp;BOOK NOW &nbsp;&nbsp;
          </Button>
        </Box>
      </Box>
      {/* Right Column */}
      <Box
        width="60vw"
        height="auto"
        padding="0"
        margin="0"
        sx={{
          backgroundImage: `url("../../../assets/bg.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>
    </Box>
   
  );
};

export default Landing;
