import { Box } from "@mui/material";
import GetChildren from "../../requests/getChildren";
import { useState, useEffect } from "react";
import ChildrenBar from "./childBar";
import AddChild from "./addChild";
import ChildHeader from "./childHeader";
import Programs from "./programs";
import Photos from "./photos";

const Children = () => {
  const { data } = GetChildren();
  const [childArray, setChildArray] = useState([]);
  const [selected, setSelected] = useState({});
  console.log(selected);

  useEffect(() => {
    if (data && data.length) {
      const uniqueChildren = [];
      const seenNames = new Set();

      data.forEach((obj) => {
        const fullName = `${obj.first_name}-${obj.last_name}`;
        if (!seenNames.has(fullName)) {
          seenNames.add(fullName);
          uniqueChildren.push({
            id: obj.student_id,
            first_name: obj.first_name,
            last_name: obj.last_name,
            dob: new Date(obj.date_of_birth),
            passion: obj.passion,
            disabilities: obj.disabilities,
            preferences: obj.preferences,
            allergies: obj.allergies,
          });
        }
      });
      setChildArray(uniqueChildren);
    }
  }, [data]);

  return (
    <Box>
      <Box>
        <hr />
      </Box>
      {/* ADD Child and Filter Bar */}
      <Box
        display="flex"
        flexDirection="row"
        m="30px"
        justifyContent="flex-end"
      >
        <ChildrenBar childArray={childArray} setSelected={setSelected} />
        <AddChild />
      </Box>
      {/* Header  */}
      <ChildHeader selected={selected} />
      <hr style={{ margin: "0px 30px" }} />

      {childArray.length > 0 && (
        <>
          <Box m="30px">
            <Programs selected={selected} />
          </Box>
          <hr style={{ margin: "0px 30px" }} />
        </>
      )}
    </Box>
  );
};

export default Children;
