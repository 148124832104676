import { useState, useEffect } from "react";
import axios from "axios";
import { capitalize } from "@mui/material";

const apiURL = process.env.REACT_APP_API_URL;

const GetAllAttendees = () => {
  const [attendees, setAttendees] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true); // Loading state

  const fetchAllAttendees = async () => {
    try {
      const res = await axios.get(`${apiURL}/attendees`, {
        withCredentials: true,
      });
      console.log(res.data);
      const formattedData = res.data.map((attendee) => ({
        ...attendee,
        date: new Date(attendee.date),
        attend_date: new Date(attendee.attend_date),
        student_name:
          capitalize(attendee.first_name) +
          " " +
          capitalize(attendee.last_name),
      }));
      setAttendees(formattedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllAttendees();
  }, []);

  return { attendees, loading, fetchAllAttendees };
};

export default GetAllAttendees;
