// requests/updateParent.js
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const ChangePassword = () => {
  const changePassword = async (currentPassword, newPassword) => {
    try {
      const response = await axios.post(
        `${apiURL}/change-password`,
        { currentPassword, newPassword },
        {
          withCredentials: true,
        }
      );
      return { success: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { success: false, error: error.response.data };
    }
  };

  return { changePassword };
};

export default ChangePassword;
