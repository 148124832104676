import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import UserSideBar from "./scenes/global/userSideBar";
import Children from "./scenes/children";
import Courses from "./scenes/courses";
import Logout from "./Authentication/logout";
import Events from "./scenes/events";
import AddEvent from "./scenes/addEvent";
import AddCourse from "./scenes/addCourse";
import { AuthProvider, useAuth } from "./Authentication/AuthContext";
import Tiles from "./scenes/tiles/tiles";
import FilterBar from "./scenes/tiles/filterBar";
import { useState } from "react";
import NavBar from "./scenes/global/NavBar";
import FAQ from "./scenes/faq";
import Footer from "./scenes/global/footer";
import UserCalendar from "./scenes/userCalendar";
import Schedule from "./scenes/schedule";
import Profile from "./scenes/profile";

function UserDashboard() {
  const [theme, colorMode] = useMode();
  const { isAuthenticated } = useAuth();
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [programType, setProgramType] = useState("all");

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <div className="app">
            {isAuthenticated && <UserSideBar />}
            <main className="content">
              <NavBar />
              <Routes>
                <Route
                  path="/*"
                  element={
                    <>
                      <FilterBar
                        selectedGroup={selectedGroup}
                        setSelectedGroup={setSelectedGroup}
                        selectedDays={selectedDays}
                        setSelectedDays={setSelectedDays}
                        programType={programType}
                        setProgramType={setProgramType}
                      />
                      <Tiles
                        selectedDays={selectedDays}
                        selectedGroup={selectedGroup}
                        programType={programType}
                      />
                    </>
                  }
                />
                <Route path="profile" element={<Profile />} />
                <Route path="schedule" element={<Schedule />} />
                <Route path="children" element={<Children />} />
                <Route path="courses" element={<Courses />} />
                <Route path="events" element={<Events />} />
                <Route path="calendar" element={<UserCalendar />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="logout" element={<Logout />} />
                <Route path="add-event" element={<AddEvent />} />
                <Route path="add-course" element={<AddCourse />} />
              </Routes>
            </main>
          </div>
          <Footer />
        </AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default UserDashboard;
