import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import GetCalendarPrograms from "../../requests/getCalendarPrograms";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
// import "./styles.css";

const CalendarList = ({ height }) => {
  const { currentEvents, currentCourses } = GetCalendarPrograms();
  const [filteredPrograms, setFilteredPrograms] = useState();
  useEffect(() => {
    setFilteredPrograms([...currentEvents, ...currentCourses]);
  }, [currentEvents, currentCourses]);

  const handleEventContent = (args) => {
    console.log("args", args.event._def.ui.borderColor);
    const color = args.event._def.ui.borderColor;
    const start = formatTime(args.event.start);
    const end = formatTime(args.event.end);
    const title = args.event.title;
    const instructor = args.event._def.extendedProps.instructor;

    if (args.event._context.viewApi.type === "dayGridMonth")
      return {
        html: `<div class="fc-daygrid-event-dot" style="border-color: ${color};"></div>
        <div class="fc-event-time">${start}</div>
        <div class="fc-event-title">${title}</div>`,
      };
    return {
      html: `<div style="overflow:'hidden'">
              <small>${start} - ${end}</small><br>
              <em>${title}</em><br>
              <small>Instructor: ${instructor}</small>
            </div>`,
    };
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        borderRadius: 2,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        margin: "0px",
      }}
      onClick={() => {
        window.open("/dashboard/calendar");
      }}
    >
      {/* FullCalendar with Tooltip Integration */}
      <Box width="100%">
        <FullCalendar
          height={height}
          plugins={[listPlugin]}
          initialView="list"
          scrollTime="09:00"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          events={filteredPrograms}
          eventContent={handleEventContent}
          headerToolbar={false}
          footerToolbar={false}
        />
      </Box>
    </Box>
  );
};

export default CalendarList;

const formatTime = (date) => {
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const timeString = new Date(date).toLocaleTimeString("en-US", options);
  console.log(timeString, typeof timeString);
  return timeString;
};
