import React, { useState } from "react";
import { TextField, Autocomplete } from "@mui/material";

const ProgramSelector = ({ Items, setSelected }) => {
  const [searchInput, setSearchInput] = useState("");

  const handleProgramChange = (event, value) => {
    console.log(value);
    setSelected(value?.value); // Update selected program
    setSearchInput(value ? value.name : ""); // Set search input based on selection
  };

  const programOptions = Items.map((item) => ({
    value: item.props.value, // Full program object
    label: item.props.children, // Display name
  }));

  const filteredPrograms = programOptions.filter((option) =>
    option?.label?.toLowerCase().includes(String(searchInput).toLowerCase())
  );

  return (
    <Autocomplete
      freeSolo
      options={filteredPrograms}
      getOptionLabel={(option) => option.label}
      onInputChange={(event, newInputValue) => {
        setSearchInput(newInputValue);
      }}
      onChange={handleProgramChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Program"
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "210px", textAlign: "center" }}
        />
      )}
    />
  );
};

export default ProgramSelector;
