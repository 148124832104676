import { useState, useEffect } from "react";
import axios from "axios";
import { capitalize } from "@mui/material";

const apiURL = process.env.REACT_APP_API_URL;

const GetAllEnrollments = () => {
  const [enrollments, setEnrollments] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true); // Loading state

  const fetchAllEnrollments = async () => {
    try {
      const res = await axios.get(`${apiURL}/enrollments`, {
        withCredentials: true,
      });
      console.log(res.data);
      const formattedData = res.data.map((enrollment) => ({
        ...enrollment,
        enrollment_date: new Date(enrollment.enrollment_date),
        start: new Date(enrollment.start),
        end: new Date(enrollment.end),
        student_name:
          capitalize(enrollment.first_name) +
          " " +
          capitalize(enrollment.last_name),
      }));
      setEnrollments(formattedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllEnrollments();
  }, []);

  return { enrollments, loading, fetchAllEnrollments };
};

export default GetAllEnrollments;
