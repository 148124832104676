import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useSpring, animated } from "react-spring";

const EventsCard = () => {
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: 14 },
    config: { duration: 1000 },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        borderRadius: 2,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        minWidth: 150,
        minHeight: 100,
        cursor: "pointer",
      }}
      onClick={() => {
        window.open("/dashboard/events");
      }}
    >
      <Box display="flex" width="100%" justifyContent="space-between">
        <Box alignItems="left" display="">
          <Typography variant="h5" color="primary" textAlign="left">
            No. of Events
          </Typography>

          <Typography variant="h1" color="black">
            <animated.div>{number.to((n) => Math.floor(n))}</animated.div>
          </Typography>

          <Box flexDirection="row" display="flex" alignItems="center">
            <ArrowUpwardIcon sx={{ color: "#4cceac" }} />

            <Typography color="#4cceac" ml="2pxs">
              30%{" "}
            </Typography>

            <Typography variant="subtitle1" color="textSecondary" ml="5px">
              Since last month
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p="7px"
          backgroundColor="#EF5340"
          height="fit-content"
          borderRadius="50%"
        >
          <ViewListIcon sx={{ fontSize: "30px", color: "black" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default EventsCard;
