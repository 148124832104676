import GetAllPrograms from "../../requests/getPreviousPrograms";
import { MenuItem, TextField, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EnrollChild from "../../requests/postEnrollChild";
import AttendChild from "../../requests/postAttendChild";
import { useLocation } from "react-router-dom";
import ProgramSelector from "./programSelector";

const AddPreviousPrograms = () => {
  const { previousCoursesAdmin, previousEventsAdmin } = GetAllPrograms();
  const [selected, setSelected] = useState("");
  const [studentID, setStudentID] = useState("");
  const location = useLocation();
  const { bookingError, postEnrollChild, bookingConfirmed } = EnrollChild();
  const { attendConfirmed, attendError, postAttendChild } = AttendChild();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    const student_id = pathSegments[pathSegments.length - 1];
    setStudentID(student_id);
  }, [location.pathname]);

  const previousCoursesMenuItems = previousCoursesAdmin.map(
    (previousCourse) => (
      <MenuItem
        key={previousCourse.course_id}
        value={previousCourse}
        name={previousCourse.name}
      >
        {previousCourse.name}
      </MenuItem>
    )
  );

  const previousEventsMenuItems = previousEventsAdmin.map((previousEvent) => (
    <MenuItem
      key={previousEvent.event_id}
      value={previousEvent}
      name={previousEvent.name}
    >
      {previousEvent.name}
    </MenuItem>
  ));

  const handleAddPreviousCourse = async () => {
    await postEnrollChild(selected.course_id, studentID);
    console.log(bookingConfirmed, bookingError);
    if (bookingConfirmed) {
      setSnackbarMessage("Course added successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      window.location.reload();
    }
    if (bookingError) {
      setSnackbarMessage("Error adding course.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    setSelected("");
  };

  const handleAddPreviousEvent = async () => {
    await postAttendChild(selected.event_id, studentID);
    console.log(bookingConfirmed, bookingError);
    if (attendConfirmed) {
      setSnackbarMessage("Event added successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      window.location.reload();
    }
    if (attendError) {
      setSnackbarMessage("Error adding event.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    setSelected("");
  };

  return {
    PreviousCourseMenu: (
      <Box display="flex" flexDirection="row" alignItems="center">
        <ProgramSelector
          Items={previousCoursesMenuItems}
          setSelected={setSelected}
        />
        <AddCircleIcon
          sx={{ fontSize: "32px" }}
          onClick={handleAddPreviousCourse}
        />
      </Box>
    ),
    PreviousEventsMenu: (
      <Box display="flex" flexDirection="row" alignItems="center">
        <ProgramSelector
          Items={previousEventsMenuItems}
          setSelected={setSelected}
        />
        <AddCircleIcon
          sx={{ fontSize: "32px" }}
          onClick={handleAddPreviousEvent}
        />
      </Box>
    ),
    snackbarMessage,
    setSnackbarMessage,
    setSnackbarSeverity,
    snackbarSeverity,
    snackbarOpen,
    setSnackbarOpen,
  };
};

export const usePreviousProgramsMenus = () => {
  return AddPreviousPrograms();
};
