import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState } from "react";
import { Dialog, Box } from "@mui/material";
// import AddChildForm from "./addChildForm.jsx";
import AddChildForm from "./addChildForm/addChildForm";

const AddChild = () => {
  const [childModal, openChildModal] = useState(false);
  const handleCloseModal = () => {
    openChildModal(false);
  };
  const handleAddChild = () => {
    openChildModal(true);
    console.log("Adding child");
  };

  return (
    <Box display="flex" marginLeft="5px">
      <AddCircleIcon
        onClick={handleAddChild}
        style={{ margin: "auto", fontSize: "30px" }}
      />
      <Dialog
        open={childModal}
        onClose={handleCloseModal}
        sx={{
          minWidth: "100%",
          alignSelf: "center",
        }}
      >
        <AddChildForm
          modalOpen={childModal}
          handleCloseModal={handleCloseModal}
        />
      </Dialog>
    </Box>
  );
};

export default AddChild;
