import { Box } from "@mui/material";
import Header from "../../components/Header";
import StudentCard from "./cards/studentCard";
import EnrollmentCard from "./cards/enrollmentCard";
import CoursesCard from "./cards/coursesCard";
import EventsCard from "./cards/eventsCard";
import BarGraph from "./charts/barGraph";
import CoursesPieChart from "./charts/coursesPieChart";
import EnrollmentsPieChart from "./charts/enrollmentsPieChart";
import CalendarList from "./calendarList";

const Dashboard = () => {
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your Dashboard" />
      </Box>
      <Box
        m="40px 10px"
        display="grid"
        gridTemplateColumns={{
          xs: "1fr",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        }}
        gap={2}
      >
        <StudentCard />
        <EnrollmentCard />
        <CoursesCard />
        <EventsCard />
      </Box>

      <Box
        m="40px 10px"
        display="grid"
        gridTemplateColumns={{
          xs: "auto",
          sm: "auto",
          md: "1.6fr 1fr",
        }}
        gap={2}
      >
        <Box gridRow="1/3">
          {/* <BarGraph height={490} /> */}
          <CalendarList height={530} />
        </Box>
        <Box>
          <CoursesPieChart height={200} />
        </Box>

        {/* <CalendarList height={200} /> */}
        <EnrollmentsPieChart height={200} />
      </Box>
    </Box>
  );
};

export default Dashboard;

// FIRST ROW
// total students last month vs this month done ✅
// total enrollments last month vs this month done ✅
// total courses last month vs this month  ✅
// total events last month vs this month  ✅
//
// SECOND ROW
// trend of students over the months
// total number of all paid vs unpaid claims
// number of enrollments paid vs unpaid
// courses or events today
