import {
  Box,
  Typography,
  TextField,
  MenuItem,
  capitalize,
} from "@mui/material";
import { useEffect, useState } from "react";

const ChildrenBar = ({ childArray, setSelected }) => {
  console.log(childArray[0]);
  const [selectedChild, setSelectedChild] = useState("");

  useEffect(() => {
    if (childArray.length > 0) {
      setSelected(childArray[0]); // Set the default child
      setSelectedChild(childArray[0].first_name); // Set local state for the selected child
    }
  }, [childArray, setSelected]);

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedChild(value);
    const newSelected = childArray.find((child) => child.first_name === value);
    setSelected(newSelected || "select");
  };

  return (
    <Box>
      <TextField
        select
        value={selectedChild}
        onChange={handleSelectChange}
        variant="outlined"
        size="small"
        sx={{ flexGrow: 1, minWidth: "150px", textAlign: "center" }}
      >
        {childArray.length === 0 && (
          <MenuItem value="select" selected>
            Select Child
          </MenuItem>
        )}
        {childArray.map((child) => (
          <MenuItem
            key={child.first_name}
            value={child.first_name}
            sx={{ textAlign: "center" }}
          >
            {capitalize(child.first_name)}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default ChildrenBar;
